
import React from 'react';

const ChatRoomHover = () => {
  return (
    <svg  version="1.0" xmlns="http://www.w3.org/2000/svg"  width="14pt" height="14pt" viewBox="0 0 96.000000 96.000000"  preserveAspectRatio="xMidYMid meet">  <g transform="translate(0.000000,96.000000) scale(0.050000,-0.050000)" fill="#d81719" stroke="none"> <path d="M400 1486 l0 -195 -124 -125 -125 -126 805 0 804 0 0 320 0 320 -680 0 -680 0 0 -194z"/> <path d="M400 686 l0 -195 -124 -125 -125 -126 805 0 804 0 0 320 0 320 -680 0 -680 0 0 -194z"/> </g> </svg>   );
};

export default ChatRoomHover;


