
import React from 'react';

const ChatHover = () => {
  return (
    <svg  version="1.0" xmlns="http://www.w3.org/2000/svg"  width="14pt" height="14pt" viewBox="0 0 96.000000 96.000000"  preserveAspectRatio="xMidYMid meet">  <g transform="translate(0.000000,96.000000) scale(0.050000,-0.050000)" fill="#d81719" stroke="none"> <path d="M480 1680 l0 -80 560 0 560 0 0 -400 0 -400 80 0 80 0 0 480 0 480 -640 0 -640 0 0 -80z"/> <path d="M160 760 l0 -679 154 159 154 160 486 0 486 0 0 520 0 520 -640 0 -640 0 0 -680z"/> </g> </svg>   );
};

export default ChatHover;


