import React from 'react';
import InlineSVG from 'react-inlinesvg';

export const convertToCurrency = input => {
  let number = Number(input);
  
  if (!isNaN(number)) {
    // Round the number down
    number = Math.floor(number * 100) / 100;

    // Format number for K (thousands) and M (millions)
    let formattedNumber = '';
    if (number >= 1000000) {
      // For millions
      formattedNumber = (number / 1000000).toFixed(1) + 'M';
    } else if (number >= 1000) {
      // For thousands
      formattedNumber = (number / 1000).toFixed(1) + 'K';
    } else {
      // For numbers less than 1000
      let [whole, decimal] = number.toFixed(2).split('.');
      whole = whole.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

      if (decimal) {
        decimal = decimal.replace(/0+$/, '');
      }

      formattedNumber = decimal ? `${whole}.${decimal}` : whole;
    }

    return (
      <>
        <InlineSVG
          src={`<svg id="rps" width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.68369 1.51631L2.54025 0.0329233C2.40347 -0.0157117 2.25215 -0.0102836 2.11954 0.0480146C1.98692 0.106313 1.88386 0.212714 1.83298 0.343847L0.536309 3.67243C-0.0137925 5.07969 -0.140706 6.30001 0.155562 7.33341C0.451831 8.36681 1.21922 9.45975 2.45773 10.6122L4.36737 8.98074C3.6153 8.24206 3.14379 7.60703 2.95283 7.07563C2.76177 6.54097 2.75434 5.96166 2.93161 5.42265L4.89075 6.12364C5.02754 6.17227 5.17885 6.16684 5.31147 6.10855C5.44408 6.05025 5.54715 5.94385 5.59803 5.81271L7.00668 2.19469C7.05712 2.06362 7.05148 1.91874 6.99097 1.79165C6.93046 1.66456 6.82 1.56558 6.68369 1.51631V1.51631Z" fill="#505AC8"/>
            <path d="M7.43667 6.01953L11.4597 5.11465C11.5256 5.09988 11.5938 5.09807 11.6604 5.10935C11.727 5.12062 11.7907 5.14475 11.8479 5.18036C11.9051 5.21597 11.9546 5.26236 11.9936 5.31688C12.0327 5.3714 12.0604 5.43299 12.0754 5.49811L12.845 8.8265C13.1697 10.2343 13.129 11.4122 12.723 12.3602C12.3169 13.3082 11.4659 14.2561 10.17 15.2041L8.61311 13.4161C9.40474 12.8056 9.92296 12.2588 10.1678 11.7758C10.4151 11.2904 10.4983 10.7395 10.4053 10.2039L8.50426 10.6319C8.3713 10.6618 8.23177 10.6383 8.11633 10.5666C8.00088 10.4949 7.91896 10.3809 7.88855 10.2495L7.05405 6.62785C7.02368 6.49709 7.04686 6.35977 7.11853 6.24582C7.1902 6.13187 7.30455 6.05053 7.43667 6.01953V6.01953Z" fill="#8189DB"/>
          </svg>
        `}
        />
        {formattedNumber}
      </>
    );
  } else {
    return input;
  }
};