

import React from 'react';

const ManageHover = () => {
  return (
	<svg  version="1.0" xmlns="http://www.w3.org/2000/svg"  width="15pt" height="15pt" viewBox="0 0 96.000000 96.000000"  preserveAspectRatio="xMidYMid meet">  <g transform="translate(0.000000,96.000000) scale(0.050000,-0.050000)" fill="#d81719" stroke="none"> <path d="M774 1746 c-5 -9 -16 -54 -23 -100 -22 -128 -157 -201 -290 -156 -106 36 -97 43 -216 -168 l-72 -128 74 -59 c110 -88 110 -262 0 -350 l-74 -59 72 -128 c119 -211 110 -204 218 -167 88 30 96 29 160 -10 97 -59 97 -58 97 139 0 142 -8 196 -40 255 -166 309 227 613 491 381 46 -40 79 -93 94 -149 l23 -87 156 0 156 0 0 58 c0 43 19 74 73 117 l74 59 -72 128 c-119 211 -110 204 -217 168 -136 -47 -258 23 -289 164 l-21 96 -182 6 c-101 3 -187 -2 -192 -10z"/> <path d="M880 400 l0 -400 520 0 520 0 0 400 0 400 -520 0 -520 0 0 -400z m656 -14 l-145 -146 -135 0 -136 0 0 80 0 80 106 0 c102 0 109 3 224 120 l118 120 57 -54 56 -54 -145 -146z"/> </g> </svg> 
  );
};

export default ManageHover;


