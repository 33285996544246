//  users
export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const VERIFICATION_SUCCESS = 'VERIFICATION_SUCCESS';
export const SET_UNREAD_MESSAGE_COUNT = 'SET_UNREAD_MESSAGE_COUNT';
export const SET_BALANCE = 'SET_BALANCE';
export const SET_GASFEE = 'SET_GASFEE';
export const SET_AVATAR = 'SET_AVATAR';
export const SHOW_COOKIES = 'SHOW_COOKIES';
export const TRANSACTION_LOADED = 'TRANSACTION_LOADED';
export const NEW_TRANSACTION = 'NEW_TRANSACTION';
export const SET_SEVEN_DAY_PROFIT = 'SET_SEVEN_DAY_PROFIT';
export const SET_ONE_DAY_PROFIT = 'SET_ONE_DAY_PROFIT';
export const SET_ALL_TIME_PROFIT = 'SET_ALL_TIME_PROFIT';
export const ACTIVITY_QUERY = 'ACTIVITY_QUERY';
export const PAGINATION_FOR_ACTIVITY = 'PAGINATION_FOR_ACTIVITY';
export const SET_USERNAME_PASSWORD = 'SET_USERNAME_PASSWORD';
export const SET_REFERRAL_CODE = 'SET_REFERRAL_CODE';


// admin
export const ADMIN_REGISTER_SUCCESS = 'ADMIN_REGISTER_SUCCESS';
export const ADMIN_REGISTER_FAIL = 'ADMIN_REGISTER_FAIL';
export const ADMIN_AUTH_ERROR = 'ADMIN_AUTH_ERROR';
export const ADMIN_LOGIN_SUCCESS = 'ADMIN_LOGIN_SUCCESS';
export const ADMIN_LOGIN_FAIL = 'ADMIN_LOGIN_FAIL';
export const ADMIN_LOGOUT = 'ADMIN_LOGOUT';
export const ADMIN_LOADED = 'ADMIN_LOADED';

//  messages
export const MSG_SUCCESS = 'success';
export const MSG_ERROR = 'error';
export const MSG_INFO = 'info';
export const MSG_WARNING = 'warning';
export const MSG_OPEN = 'MSG_OPEN';
export const MSG_CLOSE = 'MSG_CLOSE';

//url
export const SET_URL = 'SET_URL';

//action customer
export const CUSTOMER_QUERY = 'CUSTOMER_QUERY';
export const PAGINATION_FOR_CUSTOMER = 'PAGINATION_FOR_CUSTOMER';
export const LOADING_CUSTOMER_TABLE = 'LOADING_CUSTOMER_TABLE';
export const ADD_MAIN_INFO = 'ADD_MAIN_INFO';

// Comment actions
export const COMMENT_CREATED = 'COMMENT_CREATED';
export const COMMENT_CREATION_FAILED = 'COMMENT_CREATION_FAILED';
export const COMMENTS_LOADED = 'COMMENTS_LOADED';
export const COMMENTS_LOAD_FAILED = 'COMMENTS_LOAD_FAILED';
export const COMMENT_DELETED = 'COMMENT_DELETED';
export const COMMENT_DELETION_FAILED = 'COMMENT_DELETION_FAILED';

//action question
export const QUESTION_QUERY = 'QUESTION_QUERY';
export const PAGINATION_FOR_QUESTION = 'PAGINATION_FOR_QUESTION';
export const LOADING_QUESTION_TABLE = 'LOADING_QUESTION_TABLE';
export const QUESTION_QUERY_ONE = 'QUESTION_QUERY_ONE';
export const SET_CURRENT_QUESTION_INFO = 'SET_CURRENT_QUESTION_INFO';
export const BRAIN_GAME_TYPE_QUERY = 'BRAIN_GAME_TYPE_QUERY';
export const SET_SCORE = 'SET_SCORE';

// action mygames
export const MY_GAMES_LOADED = 'MY_GAMES_LOADED';
export const MY_GAMES_LOADED_WITHOUT_STATS = 'MY_GAMES_LOADED_WITHOUT_STATS';
export const MY_GAMES_LOADED_WITH_STATS = 'MY_GAMES_LOADED_WITH_STATS';
export const ROOM_STATISTICS_LOADED = 'ROOM_STATISTICS_LOADED';
export const MY_HISTORY_LOADED = 'MY_HISTORY_LOADED';
export const MY_CHAT_LOADED = 'MY_CHAT_LOADED';
export const NOTIFICATIONS_LOADED = 'NOTIFICATIONS_LOADED';
export const SET_NOTIFICATIONS_ROOM_INFO = 'SET_NOTIFICATIONS_ROOM_INFO';
export const SET_CHAT_ROOM_INFO = 'SET_CHAT_ROOM_INFO';

// action item
export const ITEM_QUERY_ONE = 'ITEM_QUERY_ONE';
export const PRODUCT_QUERY = 'PRODUCT_QUERY';
export const ITEM_QUERY = 'ITEM_QUERY';
export const MY_ITEM_QUERY_ONE = 'ITEM_QUERY_ONE';
export const MY_ITEM_QUERY = 'MY_ITEM_QUERYs';

// action loan
export const LOAN_QUERY_ONE = 'LOAN_QUERY_ONE';
export const FETCH_RECENT_TRANSACTIONS_SUCCESS = 'FETCH_RECENT_TRANSACTIONS_SUCCESS';
export const LOAN_QUERY = 'LOAN_QUERY';
export const PREVIOUS_LOAN_QUERY = 'PREVIOUS_LOAN_QUERY';
export const MY_LOAN_QUERY_ONE = 'ITEM_QUERY_ONE';
export const MY_LOAN_QUERY = 'MY_LOAN_QUERYs';
export const LOADING_REMAINING_LOANS = 'LOADING_REMAINING_LOANS';
export const CALCULATE_REMAINING_LOANS = 'CALCULATE_REMAINING_LOANS';
export const SET_USER_LOANS = 'SET_USER_LOANS';
export const LOADING_PAYBACK_LOAN = 'LOADING_PAYBACK_LOAN';


// SETTINGS
export const UPDATE_PROGRESS = 'UPDATE_PROGRESS';
export const GET_QUEUE = 'GET_QUEUE';
export const ADD_TO_QUEUE = 'ADD_TO_QUEUE';

//PAGINATION
export const LOADING_LOAN_TABLE = 'LOADING_LOAN_TABLE';
export const PAGINATION_FOR_LOAN = 'PAGINATION_FOR_LOAN';
export const PAGINATION_FOR_ITEM = 'PAGINATION_FOR_ITEM';
export const LOADING_ITEM_TABLE = 'LOADING_ITEM_TABLE';

export const ADD_TOTAL = 'ADD_TOTAL';
export const MY_ADD_TOTAL = 'MY_ADD_TOTAL';
export const PRODUCT_TOTAL = 'PRODUCT_TOTAL';

export const SET_CURRENT_PRODUCT_INFO = 'SET_CURRENT_PRODUCT_INFO';
export const SET_CURRENT_PRODUCT_ID = 'SET_CURRENT_PRODUCT_ID';
export const SET_OFFER_LOAN_INFO = 'SET_OFFER_LOAN_INFO';
export const SET_OFFER_TARGET_INFO = 'SET_OFFER_TARGET_INFO';
export const SET_CURRENT_LOAN_ID = 'SET_CURRENT_LOAN_ID';
export const SET_CURRENT_LOAN_INFO = 'SET_CURRENT_LOAN_INFO';

//action landing
export const LANDING_QUERY = 'LANDING_QUERY';
export const LANDING_QUERY_ONE = 'LANDING_QUERY_ONE';
export const LOADING_LANDING_TABLE = 'LOADING_LANDING_TABLE';
export const PAGINATION_FOR_LANDING = 'PAGINATION_FOR_LANDING';
export const ADD_LANDING_TOTAL = 'ADD_LANDING_TOTAL';

//action logic
export const ACTION_ROOM = 'ACTION_ROOM';
export const SET_SOCKET = 'SET_SOCKET';
export const GAMETYPE_LOADED = 'GAMETYPE_LOADED';
export const STRATEGIES_LOADED = 'STRATEGIES_LOADED';
export const RECENTLY_CLOSED_LOADED = 'RECENTLY_CLOSED_LOADED';
export const TOP_WITHDRAWALS_LOADED = 'TOP_WITHDRAWALS_LOADED';

export const ROOMINFO_LOADED = 'ROOMINFO_LOADED';
export const ROOMS_LOADED = 'ROOMS_LOADED';
export const ROOMS_COUNT = 'ROOMS_COUNT';
export const ATTACHED = 'ATTACHED';

export const SET_GAME_MODE = 'SET_GAME_MODE';
export const RPSBETITEMS_LOADED = 'RPSBETITEMS_LOADED';
export const QSBETITEMS_LOADED = 'QSBETITEMS_LOADED';

export const SET_CUR_ROOM_INFO = 'SET_CUR_ROOM_INFO';
export const BET_SUCCESS = 'BET_SUCCESS';
export const BET_FAIL = 'BET_FAIL';
export const HISTORY_LOADED = 'HISTORY_LOADED';
export const TOP_WINS = 'TOP_WINS';
export const START_LOADING = 'START_LOADING';
export const START_BET = 'START_BET';
export const END_BET = 'END_BET';
export const TNX_COMPLETE = 'TNX_COMPLETE';
export const TNX_INCOMPLETE = 'TNX_INCOMPLETE';
export const END_LOADING = 'END_LOADING';
export const UPDATE_BET_RESULT = 'UPDATE_BET_RESULT';
export const SPLEESH_GUESSES = 'SPLEESH_GUESSES';
export const DROP_GUESSES = 'DROP_GUESSES';
export const BANG_GUESSES = 'BANG_GUESSES';
export const ROLL_GUESSES = 'ROLL_GUESSES';
export const UPDATE_BANKROLL = 'UPDATE_BANKROLL';
export const UPDATE_RAIN = 'UPDATE_RAIN';

export const UPDATE_BANKROLL_QS = 'UPDATE_BANKROLL_QS';


export const MSG_CREATE_ROOM_FAIL = 'MSG_CREATE_ROOM_FAIL';
export const MSG_CREATE_ROOM_SUCCESS = 'MSG_CREATE_ROOM_SUCCESS';
export const MSG_ROOMS_LOAD_FAILED = 'MSG_ROOMS_LOAD_FAILED';
export const MSG_GAMETYPE_LOAD_FAILED = 'MSG_GAMETYPE_LOAD_FAILED';

export const CLOSE_ALERT_MODAL = 'CLOSE_ALERT_MODAL';
export const OPEN_GAME_PASSWORD_MODAL = 'OPEN_GAME_PASSWORD_MODAL';
export const CLOSE_GAME_PASSWORD_MODAL = 'CLOSE_GAME_PASSWORD_MODAL';
export const OPEN_CONFIRM_TRADE_MODAL = 'OPEN_CONFIRM_TRADE_MODAL';
export const CLOSE_CONFIRM_TRADE_MODAL = 'CLOSE_CONFIRM_TRADE_MODAL';
export const OPEN_CONFIRM_LOAN_MODAL = 'OPEN_CONFIRM_LOAN_MODAL';
export const CLOSE_CONFIRM_LOAN_MODAL = 'CLOSE_CONFIRM_LOAN_MODAL';
export const OPEN_OFFER_LOAN_MODAL = 'OPEN_OFFER_LOAN_MODAL';
export const CLOSE_OFFER_LOAN_MODAL = 'CLOSE_OFFER_LOAN_MODAL';
export const OPEN_LIST_ITEM_MODAL = 'OPEN_LIST_ITEM_MODAL';
export const CLOSE_LIST_ITEM_MODAL = 'CLOSE_LIST_ITEM_MODAL';
export const OPEN_DELIST_ITEM_MODAL = 'OPEN_DELIST_ITEM_MODAL';
export const CLOSE_DELIST_ITEM_MODAL = 'CLOSE_DELIST_ITEM_MODAL';
export const OPEN_LIST_LOAN_MODAL = 'OPEN_LIST_LOAN_MODAL';
export const CLOSE_LIST_LOAN_MODAL = 'CLOSE_LIST_LOAN_MODAL';
export const OPEN_DELIST_LOAN_MODAL = 'OPEN_DELIST_LOAN_MODAL';
export const CLOSE_DELIST_LOAN_MODAL = 'CLOSE_DELIST_LOAN_MODAL';
// export const FETCH_ACCESSORY_SUCCESS = 'FETCH_ACCESSORY_SUCCESS';
export const SET_DARK_MODE = 'SET_DARK_MODE';
export const SET_SPECTATE_MODE = 'SET_SPECTATE_MODE';
export const SET_NOTIFICATIONS_ALLOWED = 'SET_NOTIFICATIONS_ALLOWED';
export const SET_FOCUSED = 'SET_FOCUSED';
export const TOGGLE_MUTE = 'TOGGLE_MUTE';
export const TOGGLE_CLICK_SOUNDS = 'TOGGLE_CLICK_SOUNDS';
export const TOGGLE_GAME_SOUNDS = 'TOGGLE_GAME_SOUNDS';
export const TOGGLE_NOTIFICATION_SOUNDS = 'TOGGLE_NOTIFICATION_SOUNDS';
export const TOGGLE_MUSIC_ENABLED = 'TOGGLE_MUSIC_ENABLED';
export const TOGGLE_LOW_GRAPHICS = 'TOGGLE_LOW_GRAPHICS';
export const TOGGLE_DRAWER = 'TOGGLE_DRAWER';
export const SET_PASSWORD_CORRECT = 'SET_PASSWORD_CORRECT';

export const ONLINE_USER_LIST_UPDATED = 'ONLINE_USER_LIST_UPDATED';

export const SELECT_MAIN_TAB = 'SELECT_MAIN_TAB';
export const GLOBAL_CHAT_RECEIVED = 'GLOBAL_CHAT_RECEIVED';
export const SET_GLOBAL_CHAT = 'SET_GLOBAL_CHAT'
export const LOAD_LEADERBOARDS = 'LOAD_LEADERBOARDS';
export const LOADING_LEADERBOARDS_TABLE = 'LOADING_LEADERBOARDS_TABLE';
export const LOAD_EQUITY_CHART = 'LOAD_EQUITY_CHART';
export const LOADING_EQUITY_CHART = 'LOADING_EQUITY_CHART';
