
import React from 'react';

const ShowHistory = () => {
  return (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"  width="20.000000pt" height="20.000000pt" viewBox="0 0 96.000000 96.000000"  preserveAspectRatio="xMidYMid meet">  <g transform="translate(0.000000,96.000000) scale(0.050000,-0.050000)" fill="#999999" stroke="none"> <path d="M240 960 l0 -720 200 0 200 0 0 80 0 80 -120 0 -120 0 0 440 0 440 560 0 560 0 0 -248 c0 -245 1 -248 48 -260 27 -6 63 -17 80 -24 29 -11 32 36 32 460 l0 472 -720 0 -720 0 0 -720z"/> <path d="M560 960 c0 -80 0 -80 80 -80 80 0 80 0 80 80 0 80 0 80 -80 80 -80 0 -80 0 -80 -80z"/> <path d="M880 960 l0 -80 240 0 240 0 0 80 0 80 -240 0 -240 0 0 -80z"/> <path d="M560 640 c0 -80 0 -80 80 -80 80 0 80 0 80 80 0 80 0 80 -80 80 -80 0 -80 0 -80 -80z"/> <path d="M1147 604 c-121 -43 -225 -117 -299 -215 l-53 -69 55 -71 c238 -312 730 -330 989 -35 99 112 99 100 0 212 -163 186 -459 262 -692 178z m324 -173 c99 -99 29 -271 -111 -271 -82 0 -160 78 -160 160 0 82 78 160 160 160 39 0 80 -18 111 -49z"/> </g> </svg> 
  );
};

export default ShowHistory;


