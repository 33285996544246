import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  actionRoom,
  getRoomList,
  setCurRoomInfo,
  fetchMoreRooms,
  getRecentlyClosed,
  getQbot
} from '../../redux/Logic/logic.actions';
import { Box, Button, Typography, IconButton, ButtonGroup, TextField, Table, TableBody, TableCell, TableRow, Tooltip } from '@material-ui/core';
import { Warning, Add, Remove, Visibility } from '@material-ui/icons';
// import ReactApexChart from 'react-apexcharts';
import { Help } from '@material-ui/icons';
import Moment from 'moment';
import { faHeart, faHeartBroken, faStopwatch } from '@fortawesome/free-solid-svg-icons';
import animationData from '../LottieAnimations/live';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faAdjust } from '@fortawesome/free-solid-svg-icons';
import {
  addNewTransaction,
  coHost
} from '../../redux/Logic/logic.actions';
import { setBalance } from '../../redux/Auth/user.actions';

import busdSvg from '../JoinGame/busd.svg';
// import { renderLottieAvatarAnimation } from '../../util/LottieAvatarAnimations';

import Avatar from '../../components/Avatar';
import AddBoxOutlined from '@material-ui/icons/AddBoxOutlined';
// import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
// import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Modal from 'react-modal';
import { convertToCurrency } from '../../util/conversion';
import Lottie from 'react-lottie';
import { getRoomStatisticsData } from '../../redux/Customer/customer.action';
// import blob from '../LottieAnimations/blob.json';
import Battle from '../icons/Battle';
import axios from '../../util/Api';

import {
  ThumbUp,
  ThumbDown,
  ThumbUpOutlined,
  ThumbDownOutlined
} from '@material-ui/icons';

import { alertModal } from '../modal/ConfirmAlerts';
import PlayerModal from '../modal/PlayerModal';

import like from '../LottieAnimations/like.json';
import loadingChart from '../LottieAnimations/loadingChart.json';
import history from '../../redux/history';
const gifUrls = ['/img/rock.gif', '/img/paper.gif', '/img/scissors.gif'];
const randomGifUrl = gifUrls[Math.floor(Math.random() * gifUrls.length)];
const customStyles = {
  overlay: {
    zIndex: 3,
    backgroundColor: 'rgba(0, 0, 0, 0.8)'
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    background: 'transparent',
    border: 'none',
    padding: 0
  }
};

class OpenGamesTable extends Component {
  constructor(props) {
    super(props);
    this._isMounted = true;
    this.state = {
      selectedGameType: this.props.selectedGameType,
      mouseDown: false,
      roomList: [],
      qBotStatuses: {},
      fetchedRoomIds: [],
      fetchedDataIds: [],
      showPopup: false,
      invertFilter: false,
      actionList: null,
      isCoHostModalOpen: false,
      isFocused: false,
      isLoading: true,
    };
  }

  // generateGameTypePanel = () => {
  //   const { isLowGraphics, selectedGameType } = this.props;
  //   const gameTypeStyleClass = {
  //     R: 'roll',
  //     RPS: 'rps',
  //     'S!': 'spleesh',
  //     MB: 'mystery-box',
  //     BG: 'brain-game',
  //     QS: 'quick-shoot',
  //     DG: 'drop-game',
  //     'B!': 'bang',
  //     BJ: 'blackjack',
  //     CR: 'craps'
  //   };

  //   const gameTypePanel = (
  //     <Box
  //       display="flex"
  //       justifyContent="space-evenly"
  //       flexWrap="nowrap"
  //       gap="15px"
  //       ref={ref => (this.game_type_panel = ref)}
  //     >
  //       <Box key="open-game-left-button">
  //         <Button
  //           className="btn-arrow-left"
  //           onClick={this.handleBtnLeftClicked}
  //         >
  //           <ChevronLeftIcon />
  //         </Button>
  //       </Box>

  //       <Button
  //         className={`btn-game-type title btn-icon all-games ${selectedGameType === 'All' ? 'active' : ''
  //           }`}
  //         key="open-game-all-game-button"
  //         onClick={() => {
  //           this.handleGameTypeButtonClicked('All');
  //         }}
  //       >
  //         {selectedGameType === 'All' && (
  //           <Lottie
  //             options={{
  //               loop: false,
  //               autoplay: false,
  //               animationData: blob
  //             }}
  //             style={{
  //               width: '100px',
  //               position: 'absolute',
  //               // filter: 'hue-rotate(222deg)',
  //               opacity: '0.4',
  //               margin: '0px -18px 0 auto',
  //               zIndex: '-1'
  //             }}
  //           />
  //         )}
  //         <div className="icon">
  //           <img src={`/img/gametype/icons/All.svg`} alt={`All Games`} />
  //           <span>All Games</span>
  //         </div>
  //       </Button>
  //       {this.props.gameTypeList.map((gameType, index) => (
  //         <Button
  //           className={`btn-game-type title btn-icon ${gameTypeStyleClass[gameType.short_name]
  //             } ${selectedGameType === gameType.short_name
  //               ? 'active'
  //               : ''
  //             }`}
  //           key={index}
  //           onClick={() => {
  //             this.handleGameTypeButtonClicked(gameType.short_name);
  //           }}
  //         >
  //           {selectedGameType === gameType.short_name && (
  //             <Lottie
  //               options={{
  //                 loop: false,
  //                 autoplay: false,
  //                 animationData: blob
  //               }}
  //               style={{
  //                 width: '100px',
  //                 position: 'absolute',
  //                 // filter: 'hue-rotate(222deg)',
  //                 opacity: '0.4',
  //                 margin: '0px -18px 0 auto',
  //                 zIndex: '-1'
  //               }}
  //             />
  //           )}
  //           <div className="icon">
  //             <img
  //               src={`/img/gametype/icons/${gameType.short_name}.svg`}
  //               alt={gameType.game_type_name}
  //             />
  //             <span>{gameType.game_type_name}</span>
  //           </div>
  //         </Button>
  //       ))}
  //       <Button
  //         className="btn-arrow-right"
  //         key="open-game-right-button"
  //         onClick={this.handleBtnRightClicked}
  //       >
  //         <ChevronRightIcon />
  //       </Button>
  //     </Box>
  //   );

  //   return gameTypePanel;
  // };

  async componentDidMount() {
    this.props.getRoomList({ pageSize: 7 });
    await this.props.getRecentlyClosed();
    window.addEventListener('load', this.handleLoad);
    this.debouncedHandleScroll = this.debounce(this.handleScroll, 300);

    window.addEventListener('scroll', this.debouncedHandleScroll);

    const qBotStatuses = {};
    for (const room of this.state.roomList) {
      const isQbot = await this.props.getQbot(room.creator_id);
      qBotStatuses[room.creator_id] = isQbot;
    }
    this.setState({ qBotStatuses });
    this.props.handleRefresh();
  }


  componentWillUnmount() {
    window.removeEventListener('scroll', this.debouncedHandleScroll);
    window.removeEventListener('load', this.handleLoad);
  }

  componentDidUpdate(prevProps, prevState) {
    const roomIds = this.props.roomList.map(room => room._id);

    // Check if roomList has changed
    if (prevProps.roomList !== this.props.roomList && this.state.roomList.length !== this.props.roomList.length) {
      // Check for significant changes in user_bet (30% or more) or if roomList length has changed
      const significantChange = this.props.roomList.slice(0, 5).some((room, index) => {
        const prevRoom = prevProps.roomList.find(prevRoom => prevRoom._id === room._id);

        if (prevRoom) {
          const previousBet = parseFloat(prevRoom.user_bet);
          const newBet = parseFloat(room.user_bet);

          // Ensure both values are valid numbers before calculating the change
          if (!isNaN(previousBet) && !isNaN(newBet) && previousBet !== 0) {
            const change = Math.abs(newBet - previousBet) / previousBet;
            return change >= 0.3; // 30% or more change
          }
        }
        return false; // No previous room found, or invalid bet values
      }) || prevProps.roomList.length !== this.props.roomList.length && this.props.roomList.length === this.state.roomList.length;

      this.setState(prevState => ({
        roomList: [
          ...prevState.roomList,
          ...this.props.roomList.filter(room => !prevState.fetchedRoomIds.includes(room._id))
        ],
        fetchedRoomIds: [...prevState.fetchedRoomIds, ...roomIds],
        isLoading: false,
        loaded: true
      }));

      this.setState({
        roomList: this.props.roomList
      });
    
      // Call handleRefresh if there was a significant change or roomList length change
      if (significantChange) {
        this.props.handleRefresh();
      }
    }
    // if (this.state.isLoading && this.state.isLoading !== prevState.isLoading) {
    //   console.log("ceded");
    // }

    // if (prevProps.refreshing !== this.props.refreshing) {
    //   if (this.props.refreshing) {
    //     this.setState({
    //       roomList: []
    //     })
    //   }
    // }

    if (prevProps.selectedGameType !== this.props.selectedGameType) {
      this.setState({ isLoading: true });
    }
  }

  handleLoad = () => {
    this.setState({ loaded: true, selectedGameType: 'All' });
  };

  handleScroll = async () => {
    const { loading, selectedGameType, rooms_count, roomList } = this.props;
    const { fetchedRoomIds } = this.state;
    
    // Get the table element
    const tableElement = document.querySelector('.table.main-game-table');
    
    if (tableElement) {
      const { bottom } = tableElement.getBoundingClientRect();
      const viewportHeight = window.innerHeight;
      const offset = 100; // Set a small offset for better UX
  
      // Check if the table is near the bottom of the viewport
      const isTableNearBottom = bottom <= viewportHeight + offset;
  
      // Check if the document is already at the bottom
      const isAtBottom = window.innerHeight + window.scrollY >= document.body.offsetHeight;
  
      // Only fetch more rooms if the table is near the bottom or at the bottom
      if (!loading && (isTableNearBottom || isAtBottom)) {
        await this.props.fetchMoreRooms({
          pageSize: roomList.length + 3,
          game_type: selectedGameType,
          excludeIds: fetchedRoomIds,
        }, roomList);
      }
    }
  };

  debounce = (func, delay) => {
    let debounceTimer;
    return function(...args) {
      const context = this;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
  };

  handleCoHost = (row) => {
    this.setState({ isCoHostModalOpen: true, selectedRow: row });
  };

  handleCloseCoHostModal = () => {
    this.setState({ isCoHostModalOpen: false });
  };

  handleCoHostAmountChange = event => {

    this.setState({ coHostAmount: event.target.value });
  };

  togglePopup = () => {
    this.setState({ showPopup: !this.state.showPopup });
  };

  handleSendCoHost = async () => {
    const { coHostAmount, selectedRow } = this.state;
    const { balance, isDarkMode, setBalance, addNewTransaction, getRoomList, selectedGameType } = this.props;

    try {
      if (coHostAmount <= 0) {
        alertModal(isDarkMode, 'R U FURR-REAL? COHOST AMOUNT MUST BE MORE THAN 0!');
        return;
      }

      if (coHostAmount > balance) {
        alertModal(isDarkMode, 'NOT ENUFF FUNDS AT THIS MEOWMENT');
        return;
      }

      const result = await this.props.coHost(coHostAmount, selectedRow._id);

      if (result.success) {
        alertModal(isDarkMode, result.message, '-cat');
        setBalance(result.balance);
        addNewTransaction(result.newTransaction);
        await getRoomList({
          pageSize: this.state.roomList.length,
          game_type: selectedGameType,
        });
        this.handleCloseCoHostModal();
      } else {
        alertModal(isDarkMode, result.message);
      }
    } catch (e) {
      alertModal(isDarkMode, 'ROOM TOO BUSY, TRY AGAIN LATER');
    }
  };


  customFromNow(date) {
    const now = Moment();
    const duration = Moment.duration(now.diff(date));

    if (duration.asSeconds() < 60) {
      return `${Math.round(duration.asSeconds())}s ago`;
    } else if (duration.asMinutes() < 60) {
      return `${Math.round(duration.asMinutes())}m ago`;
    } else if (duration.asHours() < 24) {
      return `${Math.round(duration.asHours())}h ago`;
    } else {
      return `${Math.round(duration.asDays())}d ago`;
    }
  }

  updateFromNow(history) {
    const result = JSON.parse(JSON.stringify(history));
    for (let i = 0; i < result.length; i++) {
      result[i]['from_now'] = this.customFromNow(Moment(result[i]['updated_at']));
    }
    return result;
  }

  // getRoomData = async (roomIds) => {
  //   try {
  //     const newRoomIds = roomIds.filter(roomId => !this.state.fetchedDataIds.includes(roomId));
  //     const hostNetProfitListPromises = newRoomIds.map(roomId =>
  //       this.props.getRoomStatisticsData(roomId, 20)
  //     );

  //     const newHostNetProfitLists = await Promise.all(hostNetProfitListPromises);

  //     this.setState(prevState => {
  //       const updatedHostNetProfitLists = [...prevState.hostNetProfitLists, ...newHostNetProfitLists];
  //       const uniqueRoomIds = new Set([...prevState.fetchedDataIds, ...newRoomIds]);
  //       const updatedFetchedDataIds = Array.from(uniqueRoomIds);

  //       const netProfitsByRoom = updatedHostNetProfitLists.map(hostNetProfitList => {
  //         const hostNetProfit = hostNetProfitList.hostNetProfit;
  //         const lastIndexNetProfit = hostNetProfit[hostNetProfit.length - 1];
  //         return lastIndexNetProfit;
  //       });

  //       const hostNetProfits = updatedHostNetProfitLists.map(item => item.hostNetProfit);
  //       const hostBetsValue = updatedHostNetProfitLists.map(item => item.hostBetsValue);

  //       return {
  //         hostNetProfitLists: updatedHostNetProfitLists,
  //         fetchedDataIds: updatedFetchedDataIds,
  //         hostNetProfitList: netProfitsByRoom,
  //         actionList: {
  //           hostNetProfits,
  //           hostBetsValue
  //         }
  //       };
  //     });

  //   } catch (error) {
  //     console.error('Error fetching room data:', error);
  //   }
  // };


  handleLike = ({ _id }) => {
    const updatedRoomList = this.state.roomList.map(room => {
      if (room._id === _id) {
        const likesIndex = room.likes ? room.likes.indexOf(this.props.user._id) : -1;
        const dislikesIndex = room.dislikes ? room.dislikes.indexOf(this.props.user._id) : -1;

        if (likesIndex > -1) {
          room.likes.splice(likesIndex, 1);
        } else if (dislikesIndex > -1) {
          room.dislikes.splice(dislikesIndex, 1);
          room.likes.push(this.props.user._id);
          this.handleAction({ roomId: _id, type: 'dislike' });
        } else {
          room.likes.push(this.props.user._id);
        }

        room.likeAnimation = true;

        setTimeout(() => {
          room.likeAnimation = false;
          this.setState({ roomList: [...this.state.roomList] });
        }, 1500);
      }
      return room;
    });

    this.setState({ roomList: updatedRoomList });
    this.handleAction({ roomId: _id, type: 'like' });
  };

  // handleDislike = ({ _id }) => {
  //   const updatedRoomList = this.state.roomList.map(room => {
  //     if (room._id === _id) {
  //       const likesIndex = room.likes.indexOf(this.props.user._id);
  //       const dislikesIndex = room.dislikes.indexOf(this.props.user._id);

  //       if (dislikesIndex > -1) {
  //         room.dislikes.splice(dislikesIndex, 1);
  //       } else if (likesIndex > -1) {
  //         room.likes.splice(likesIndex, 1);
  //         room.dislikes.push(this.props.user._id);
  //         this.handleAction({ roomId: _id, type: 'like' });
  //       } else {
  //         room.dislikes.push(this.props.user._id);
  //       }
  //     }
  //     return room;
  //   });

  //   this.setState({ roomList: updatedRoomList });
  //   this.handleAction({ roomId: _id, type: 'dislike' });
  // };

  handleAction = ({ roomId, type }) => {
    const { user, pageNumber } = this.props;
    if (user && user._id) {
      this.props.actionRoom({
        roomId,
        type,
        conditions: {
          page: pageNumber !== undefined ? pageNumber : 0,
          game_type: this.props.selectedGameType
        }
      });
    }
  };

  handleView = ({ _id }) => {
    this.handleViewAction({ roomId: _id, type: 'view' });
  };

  handleViewAction = ({ roomId, type }) => {
    const { user, pageNumber, selectedGameType } = this.props;
    if (user && user._id) {
      this.props.actionRoom({
        roomId,
        type,
        conditions: {
          page: pageNumber !== undefined ? pageNumber : 0,
          game_type: selectedGameType
        }
      });
    }
  };

  joinRoom = e => {
    this.setState({ isClicked: true });

    const creator_id = e.currentTarget.getAttribute('data-creatorId');
    const bet_amount = e.currentTarget.getAttribute('data-betAmount');

    if (e.currentTarget.getAttribute('data-roomStatus') === 'finished') {
      alertModal(this.props.isDarkMode, `THIS BATTLE HAS NOW ENDED`);
      return;
    }

    const room_id = e.currentTarget.getAttribute('data-id');

    this.props.setCurRoomInfo({
      _id: room_id,
      game_type: e.currentTarget.getAttribute('data-gameType'),
      bet_amount: bet_amount,
      creator_id: creator_id,
      hosts: [],
      // endgame_amount: e.currentTarget.getAttribute('data-endgame_amount'),
      // spleesh_bet_unit: parseInt(
      //   e.currentTarget.getAttribute('data-spleeshBetUnit')
      // ),
      // box_price: parseFloat(e.currentTarget.getAttribute('data-boxPrice')),
      game_log_list: [],
      // box_list: [],
      // brain_game_type: {
      //   _id: e.currentTarget.getAttribute('data-brainGameTypeId'),
      //   game_type_name: e.currentTarget.getAttribute('data-brainGameTypeName')
      // },
      // brain_game_score: e.currentTarget.getAttribute('data-brainGameScore')
    });
    history.push('/join/' + room_id);
  };

  // handleGameTypeButtonClicked = async short_name => {
  //   await this.props.onChangeGameType(short_name, async () => {
  //     this.setState({ roomList: [], fetchedRoomIds: [] }, async () => {
  //       await this.props.getRoomList({
  //         pageSize: 7,
  //         game_type: short_name
  //       });
  //     });
  //   });
  // };

  // handleBtnLeftClicked = e => {
  //   const scrollAmount = 200;
  //   this.game_type_panel.scrollLeft -= scrollAmount;
  // };

  // handleBtnRightClicked = e => {
  //   const scrollAmount = 200;
  //   this.game_type_panel.scrollLeft += scrollAmount;
  // };

  getColorClass = (value) => {
    if (value > 0) {
      return 'green';
    } else if (value === 0) {
      return 'grey';
    } else {
      return 'red';
    }
  };


  renderArrow = value => {
    return value >= 0 ? (
      <Add style={{ color: '#57ca22' }} />
    ) : (
      <Remove style={{ color: 'red' }} />
    );
  };

  calculateColor = value => {
    return value >= 0 ? '#57ca22' : 'red';
  };

  toggleInvertFilter = () => {
    this.setState({ invertFilter: !this.state.invertFilter });
  };

  handleMaxButtonClick = () => {
    if (this.props.balance) {
      const maxPayoutAmount = this.props.balance;
      const roundedMaxPayoutAmount = Math.floor(maxPayoutAmount * 1e6) / 1e6;

      this.setState(
        {
          coHostAmount: roundedMaxPayoutAmount,
        },
        () => {
          document.getElementById('payout').focus();
        }
      );
    }
  };

  handleFocus = () => {
    this.setState({ isFocused: true });
  };

  render() {
    // const gameTypePanel = this.generateGameTypePanel();
    const {
      invertFilter,
      isFocused,
    } = this.state;
    const { showPlayerModal, selectedCreator, isLowGraphics, isDarkMode, recentlyClosed, handleOpenPlayerModal, handleClosePlayerModal } = this.props;
    let recent = recentlyClosed ? this.updateFromNow(recentlyClosed) : [];
    const defaultOptions = {
      loop: this.props.isLowGraphics ? false : true,
      autoplay: this.props.isLowGraphics ? false : true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };
    return (
      <>
        <div className="overflowX">

          {/* <div className="game-type-container">
            <div
              className="game-type-panel"
              ref={elem => {
                this.game_type_panel = elem;
              }}
            >
              {gameTypePanel}
            </div>
          </div> */}
          {/* <div className="table-header">
            <div className="table-cell room-id">Room ID</div>
            <div className="table-cell bet-info">host / PLAYER</div>
            <div className="table-cell payout">Net profit </div>
            <div className="table-cell action desktop-only">Bet/Bankroll</div>
          </div>*/}
        </div>
        {showPlayerModal && (
          <PlayerModal
            selectedCreator={selectedCreator}
            modalIsOpen={showPlayerModal}
            closeModal={handleClosePlayerModal}
          // {...this.state.selectedRow}
          />
        )}

        <div className="table main-game-table">

          {/* {this.state.isLoading ? (
            <>
              <span style={{ position: "relative", display: "flex", justifyContent: "center", alignItems: "center" }}>Fetching Rooms&nbsp;</span>

              <div className="loading-gif-container">
                <img src={randomGifUrl} id="isLoading" alt="loading" />
              </div>
            </>
          ) : (
            <> */}
          {(this.props.roomList.length === 0 && !this.state.isLoading && !this.props.loading) ? (
            <div className="dont-have-game-msg">
              <div>NO LIVE BATTLES, GO TO 'MANAGE' TO CREATE ONE</div>
            </div>
          ) : (
            <>
              {this.state.roomList.map(
                (row, key) => (
                  <div
                    className={`table-row ${key < 10 ? 'slide-in' : 'slide-in'}`}
                    style={{ animationDelay: `${key * 0.1}s` }}
                    key={row._id}
                  >
                    {' '}
                    {/* {renderLottieAvatarAnimation(
                        row.gameBackground,
                        isLowGraphics
                      )} */}
                    <div>
                      <div className="table-cell cell-room-info">
                        <img
                          src={`/img/gametype/icons/${row.game_type.short_name}.svg`}
                          alt=""
                          className="game-type-icon"
                        />
                        <div>
                          {/* <div className="cell-game-type">
                        {row.game_type.game_type_name}
                      </div> */}
                          <div className="cell-game-id">{'#' + row.index}</div>
                          {this.state.qBotStatuses[row.creator_id] && (
                            <span
                              style={{
                                fontSize: "xx-small",
                                position: "absolute",
                                top: "37px",
                                left: "110px",
                                border: "1px solid rgb(255, 208, 0)",
                                background: "linear-gradient(45deg, #7a5958, #a27955)",
                                borderRadius: "4px",
                                color: "rgb(255, 208, 0)",
                                padding: "0px 5px",
                                lineHeight: "20px",
                                marginLeft: "7.5px"
                              }}
                            >
                              Q-BOT
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="table-cell desktop-only cell-user-name">

                        <a
                          className="player"
                          onClick={() =>
                            handleOpenPlayerModal(row.creator_id)
                          }
                        >
                          <Avatar
                            className="avatar desktop-only"
                            src={row.creator_avatar}
                            accessory={row.accessory}
                            username={row.username}
                            rank={row.rank}
                            alt=""
                            darkMode={this.props.isDarkMode}
                          />
                        </a>
                        <i
                          className={`online-status${this.props.onlineUserList.filter(
                            user => user === row.creator_id
                          ).length > 0
                            ? ' online'
                            : ''
                            }`}
                        ></i>
                        {row.hosts && row.hosts
                          .slice(1, 2)
                          .map((host, index) => (
                            <div className="hosts" key={index}>
                              <a
                                className="player"
                                onClick={() => {
                                  handleOpenPlayerModal(host.host);
                                }}
                              >
                                <Avatar
                                  className="avatar desktop-only"
                                  src={host.avatar}
                                  accessory={host.accessory}
                                  username={host.username}
                                  rank={host.rank}
                                  alt=""
                                  darkMode={isDarkMode}
                                />
                              </a>
                            </div>
                          ))}

                        {row.hosts.length > 2 && (
                          <div className="hosts avatar-square">
                            <div className="avatar-count">
                              +{row.hosts.length - 2}
                            </div>
                          </div>
                        )}
                        {row.joiners && row.joiners.length > 0 ? (
                          <div className="table-cell avatar desktop-only cell-joiners">
                            <Battle />
                            {row.joiner_avatars
                              .slice(0, 2)
                              .map((joiner, index) => (
                                <a
                                  key={index}
                                  className="player"
                                  onClick={() =>
                                    handleOpenPlayerModal(row.joiners[index])
                                  }
                                >
                                  <Avatar
                                    className="avatar desktop-only"
                                    key={index}
                                    accessory={joiner.accessory}
                                    src={joiner.avatar}
                                    username={joiner.username}
                                    rank={joiner.rank}
                                    alt=""
                                    darkMode={this.props.isDarkMode}
                                  />
                                </a>
                              ))}
                            {row.joiner_avatars.length > 2 && (
                              <div className="avatar-square">
                                <div className="avatar-count">
                                  +{row.joiner_avatars.length - 2}
                                </div>
                              </div>
                            )}
                          </div>
                        ) : null}
                      </div>


                      <div className="table-cell desktop-only cell-likes title">
                        {/* <div id="view">
                              <Visibility style={{ fontSize: '1rem' }} />
                              <Typography variant="body1">
                                {row.views?.length || 0}
                              </Typography>
                            </div> */}
                        <div id="view" style={{
                          filter: isDarkMode ? "brightness(3)" : "none"
                        }}>
                          <Battle width="24px" />
                          <Typography variant="body1">
                            {row.battles}
                          </Typography>
                        </div>

                        {/* <div>
                              <IconButton color="inherit" onClick={() => this.handleLike(row)}>
                                {row.likes?.includes(this.props.user._id) ? (
                                  <>
                                    {!row.likeAnimation && (
                                      <ThumbUp
                                        style={{ fontSize: '1rem', color: 'red' }}
                                      />
                                    )}
                                    {row.likeAnimation && (
                                      <Lottie
                                        options={{
                                          loop: isLowGraphics ? false : true,
                                          autoplay: isLowGraphics ? false : true,
                                          animationData: like
                                        }}
                                        style={{
                                          width: '32px',
                                          height: '38px',
                                          margin: '-26px -8px -20px -8px'
                                        }}
                                      />
                                    )}
                                  </>
                                ) : (
                                  <ThumbUpOutlined style={{ fontSize: '1rem' }} />
                                )}
                              </IconButton>
                              <Typography variant="body1">
                                {row.likes?.length || 0}
                              </Typography>
                            </div> */}
                      </div>
                      <div
                        className="table-cell cell-action"
                        onClick={() => this.handleView(row)}
                      >
                        {row.creator_id !== this.props.user._id && (

                          <a
                            className="ml-1"
                            onClick={() => this.handleCoHost(row)}
                            style={{ color: "rgb(0 255 58)", padding: "2.5px ", display: "flex", justifyContent: "center", alignItems: "center", background: "linear-gradient(45deg, #28a74544, #33e74533)", border: "2px solid rgb(0 255 58)", cursor: "pointer", borderRadius: "0.3em", display: "inline-flex", verticalAlign: "middle" }}
                          >
                            <AddBoxOutlined style={{ width: "25px" }} /><img style={{ width: "15px" }} src={busdSvg} />
                          </a>
                        )}
                        <Button
                          className="btn_join"
                          onClick={event => {
                            event.currentTarget.classList.add('active');
                            this.joinRoom(event);
                          }}
                          data-id={row._id}
                          // data-endgame-amount={row.endgame_amount}
                          data-creator-id={row.creator_id}
                          data-room-status={row.status}
                          data-game-type={row.game_type.game_type_name}
                          data-bet-amount={row.user_bet}
                        // data-spleesh-bet-unit={row.spleesh_bet_unit}
                        // data-box-price={row.box_price}
                        // data-brain-game-type-id={
                        //   row.brain_game_type ? row.brain_game_type._id : ''
                        // }
                        // data-brain-game-type-name={
                        //   row.brain_game_type
                        //     ? row.brain_game_type.game_type_name
                        //     : ''
                        // }
                        // data-brain-game-score={
                        //   row.brain_game_score ? row.brain_game_score : 0
                        // }
                        >
                          {row.is_private && (
                            <img
                              src="/img/icon-lock.png"
                              alt=""
                              className="lock-icon"
                            />
                          )}
                          {row.game_type.game_type_name !== 'Spleesh!' &&
                            // row.game_type.game_type_name !== 'Drop Game' &&
                            row.game_type.game_type_name !== 'Mystery Box' && (
                              <>WIN&nbsp;{convertToCurrency(row.user_bet)}</>
                            )}
                        </Button>
                      </div>
                    </div>
                    <div className="mobile-only" style={{ marginTop: '7.5px', marginBottom: '-5px' }}>
                      <div className="table-cell cell-user-name">
                        <a
                          className="player"
                          onClick={() =>
                            handleOpenPlayerModal(row.creator_id)
                          }
                        >
                          <Avatar
                            className="avatar"
                            src={row.creator_avatar}
                            accessory={row.accessory}
                            username={row.username}
                            rank={row.rank}
                            alt=""
                            darkMode={this.props.isDarkMode}
                          />
                          {/* <span>{row.creator}</span> */}
                          <i
                            className={`online-status${this.props.onlineUserList.filter(
                              user => user === row.creator_id
                            ).length > 0
                              ? ' online'
                              : ''
                              }`}
                          ></i>
                        </a>
                        {row.joiners && row.joiners.length > 0 ? (
                          <div className="table-cell mobile-only cell-joiners">
                            <Battle />
                            {row.joiner_avatars
                              .slice(0, 3)
                              .map((joiner_avatar, index) => (

                                <a
                                  key={index}
                                  className="player"
                                  onClick={() =>
                                    handleOpenPlayerModal(row.joiners[index])
                                  }
                                >
                                  <Avatar
                                    className="avatar mobile-only"
                                    key={index}
                                    src={joiner_avatar.avatar}
                                    rank={joiner_avatar.rank}
                                    username={joiner_avatar.username}
                                    alt=""
                                    accessory={joiner_avatar.accessory}
                                    darkMode={this.props.isDarkMode}
                                  />
                                </a>
                              ))}
                            {row.joiner_avatars.length > 3 && (
                              <div className="avatar-square">
                                <div className="avatar-count">
                                  +{row.joiner_avatars.length - 3}
                                </div>
                              </div>
                            )}
                          </div>
                        ) : null}
                      </div>
                      <div className="table-cell mobile-only cell-likes title">
                        {/* <div id="view">
                              <Visibility style={{ fontSize: '1rem' }} />
                              <Typography variant="body1">
                                {row.views?.length || 0}
                              </Typography>
                            </div> */}
                        <div id="view" style={{
                          filter: isDarkMode ? "brightness(3)" : "none",
                          marginRight: "-5px"
                        }}>
                          <Battle width="24px" />
                          <Typography variant="body1">
                            {row.battles}
                          </Typography>
                        </div>
                        {/* <div>
                              <IconButton color="inherit" onClick={() => this.handleLike(row)}>
                                {row.likes?.includes(this.props.user._id) ? (
                                  <>
                                    {!row.likeAnimation && (
                                      <ThumbUp
                                        style={{ fontSize: '1rem', color: 'red' }}
                                      />
                                    )}
                                    {row.likeAnimation && (
                                      <Lottie
                                        options={{
                                          loop: isLowGraphics ? false : true,
                                          autoplay: isLowGraphics ? false : true,
                                          animationData: like
                                        }}
                                        style={{
                                          width: '32px',
                                          height: '38px',
                                          margin: '-26px -8px -20px -8px'
                                        }}
                                      />
                                    )}
                                  </>
                                ) : (
                                  <ThumbUpOutlined style={{ fontSize: '1rem' }} />
                                )}
                              </IconButton>
                              <Typography variant="body1">
                                {row.likes?.length || 0}
                              </Typography>
                            </div> */}
                      </div>
                    </div>
                  </div>
                ),
                this
              )}</>
          )}
          <div style={{ display: "inline-flex", justifyContent: "center", alignItems: "center" }}>

            <h2 className="main-title recently-closed-title" style={{ paddingTop: "5px" }}>RECENTLY ENDED</h2> <Lottie style={{ filter: 'grayscale(1)' }} options={defaultOptions} width={40} />
          </div>
          <div className='recently-closed'>
            {recent?.map((row) => (
              <div key={row._id} className="carousel-content table-row slide-in">
                <a href={`/join/${row._id}`} className="table-cell">
                  <img
                    src={`/img/gametype/icons/${row.game_type.short_name}.svg`}
                    alt=""
                    className="game-type-icon"
                  />
                  <div className="cell">
                    {row.game_type.game_type_name}
                  </div>
                  <div className="cell">{'-' + row.room_number}</div>
                  {this.state.qBotStatuses[row.creator_id] && (
                    <span
                      style={{
                        fontSize: "xx-small",
                        position: "absolute",
                        top: "23px",
                        left: "112px",
                        border: "1px solid rgb(255, 208, 0)",
                        background: "linear-gradient(45deg, #7a5958, #a27955)",
                        borderRadius: "4px",
                        color: "rgb(255, 208, 0)",
                        padding: "0px 5px",
                        lineHeight: "20px",
                        marginLeft: "7.5px"
                      }}
                    >
                      Q-BOT
                    </span>
                  )}
                </a>
                <div className="cell">
                  {row.status}&nbsp;{<FontAwesomeIcon icon={faHeartBroken} />}
                </div>
                <div className="cell">{row.from_now}&nbsp;{<FontAwesomeIcon icon={faStopwatch} />}</div>
                <div className={"cell"}>
                  <span className={`${this.getColorClass(row.host_pr - row.bet_amount)}`}>{convertToCurrency(row.host_pr - row.bet_amount)}</span>
                </div>
                <a
                  href="#"
                  className="player"
                  onClick={() => handleOpenPlayerModal(row.creator._id)}
                >
                  <Avatar
                    className="avatar"
                    src={row.creator.avatar}
                    accessory={row.creator.accessory}
                    username={row.creator.username}
                    rank={row.creator.totalWagered}
                    alt=""
                    darkMode={this.props.isDarkMode}
                  />
                  <i
                    className={`online-status${this.props.onlineUserList.filter((user) => user === row.creator._id).length > 0
                      ? ' online'
                      : ''
                      }`}
                  ></i>
                </a>
              </div>
            ))}
          </div>
          {/* </>

          )} */}

          {this.props.loading && (!this.state.isLoading) && (
            <>
              <div className="loading-spinner">
              </div>
            </>
          )}
        </div>
        <Modal
          isOpen={this.state.isCoHostModalOpen}
          onRequestClose={this.handleCloseCoHostModal}
          style={customStyles}
          contentLabel="CoHost Modal"
        >
          <div className={`${this.props.isDarkMode ? 'dark_mode' : ''} big-modal`}>
            <div className="modal-header">
              <h2 className="modal-title title">
                <FontAwesomeIcon icon={faUsers} className="mr-2" />
                CO-HOST
              </h2>
              <Button className="btn-close title" onClick={this.handleCloseCoHostModal}>
                ×
              </Button>
            </div>
            <div className="modal-body">
              <div className="modal-content-wrapper">
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <h1 className='modal-title title'>BECOME A CO-HOST</h1>
                  <div style={{ width: '50%', textAlign: 'right', padding: '20px' }}>
                    <span>HELP</span>&nbsp;
                    <span>
                      <Help style={{ width: '16', marginTop: '-3px', cursor: 'pointer' }} onClick={this.togglePopup} />
                    </span>
                  </div>
                </div>
                <div className="modal-content-panel">
                  <div className="input-amount">
                    <TextField
                      label="Amount"
                      value={this.state.coHostAmount || 0}
                      onChange={this.handleCoHostAmountChange}
                      pattern="^\\d*\\.?\\d*$"
                      variant="filled"
                      type="number"
                      autoComplete="off"
                      id="payout"
                      className="form-control"
                      InputProps={{
                        onFocus: this.handleFocus,
                        endAdornment: !this.state.isFocused ? ' RPS ' : (
                          <ButtonGroup
                            className={isFocused ? 'fade-in' : 'fade-out'}
                          >
                            <Button
                              onClick={() => this.handleMaxButtonClick()}
                              style={{ marginRight: '-10px' }}
                            >
                              Max
                            </Button>
                          </ButtonGroup>
                        ),
                      }}
                    />
                  </div>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <span>IN-GAME BALANCE:</span>
                        </TableCell>
                        <TableCell>
                          {convertToCurrency(this.props.balance)}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <span>NEW BALANCE:</span>
                        </TableCell>
                        <TableCell>
                          {convertToCurrency(
                            (this.props.balance || 0) - (this.state.coHostAmount || 0)
                          )}
                          &nbsp;
                          {((this.props.balance || 0) - (this.state.coHostAmount || 0)) < 0 && <Warning width="15pt" />}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <span>CURRENT BANKROLL:</span>
                        </TableCell>
                        <TableCell style={{ color: "#ffb000" }}>
                          {this.state.selectedRow ? (
                            convertToCurrency(this.state.selectedRow.user_bet)
                          ) : (
                            null
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <span>NEW BANKROLL:</span>
                        </TableCell>
                        <TableCell style={{ color: "#ffb000" }}>
                          {this.state.selectedRow ? (
                            convertToCurrency(parseFloat(this.state.selectedRow.user_bet) + parseFloat(this.state.coHostAmount || 0))
                          ) : (
                            null
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <span>CURRENT SHARE:</span>
                        </TableCell>
                        {this.state.selectedRow ? (
                          <TableCell style={{ color: 'red' }}>
                            {this.state.selectedRow.hosts && this.state.selectedRow.hosts.some(host => host.host === this.props.user._id) ? (
                              <>
                                {`${(this.state.selectedRow.hosts.find(host => host.host === this.props.user._id).share).toFixed(2)}%`}
                              </>
                            ) : (
                              convertToCurrency('0')
                            )}
                          </TableCell>
                        ) : (
                          null
                        )}
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <span>NEW SHARE:</span>
                        </TableCell>
                        {this.state.selectedRow ? (
                          <TableCell style={{ color: 'red' }}>
                            {this.state.selectedRow.hosts && this.state.selectedRow.hosts.some(host => host.host === this.props.user._id) ? (
                              <>
                                {(`
                                ${(((((this.state.selectedRow.hosts.find(host => host.host === this.props.user._id).share) / 100) *
                                    parseFloat(this.state.selectedRow.user_bet)) + parseFloat(this.state.coHostAmount || 0)) / (parseFloat(this.state.selectedRow.user_bet) + parseFloat(this.state.coHostAmount || 0)) * 100).toFixed(2)}%
                                `)}
                              </>
                            ) : (
                              <>
                                {(((parseFloat(this.state.coHostAmount || 0)) / (parseFloat(this.state.coHostAmount || 0) + parseFloat(this.state.selectedRow.user_bet))) * 100).toFixed(2)}%
                              </>
                            )}
                          </TableCell>

                        ) : (
                          null
                        )}
                      </TableRow>
                    </TableBody>
                  </Table>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <Button className="btn-submit" onClick={this.handleSendCoHost}>
                CONTRIBUTE
              </Button>
              <Button
                className="btn-back"
                onClick={this.handleCloseCoHostModal}
              >
                Cancel
              </Button>
            </div>
          </div>
          {this.state.showPopup && (
            <div className={`${isDarkMode ? 'popup-overlay dark_mode' : 'popup-overlay'}`}>
              <div className="popup title">
                <h2 className='modal-title title' style={{ textAlign: 'center', marginBottom: "20px" }}>Co-Hosting Information</h2>
                <div className="popup-content">
                  <div style={{ position: 'relative' }}>
                    <img src={'../img/co-host.svg'} style={{ borderRadius: '20px', border: '1px solid aaa9', overflow: 'hidden', filter: invertFilter ? 'invert(1)' : 'none', }} />
                    <Button
                      onClick={this.toggleInvertFilter}
                      style={{ position: 'absolute', bottom: '10px', right: '10px', borderRadius: "100px", padding: "0" }}
                    >
                      <FontAwesomeIcon icon={faAdjust} />
                    </Button>
                  </div>
                  <h3 style={{ fontSize: '24px', fontWeight: '600', marginBottom: '15px', textAlign: 'center', letterSpacing: '0.5px' }}>
                    Co-Hosting Overview
                  </h3>
                  <p style={{ fontSize: '16px', lineHeight: '1.6', marginBottom: '15px', textAlign: 'justify', padding: '0 20px' }}>
                    Co-Hosting allows you to invest in existing games and own a share of its profits.
                  </p>
                  <p style={{ fontSize: '16px', fontWeight: '600', color: '#16e100', marginTop: '20px', marginBottom: '10px', padding: '0 20px' }}>
                    Steps to become a co-host:
                  </p>
                  <ol style={{ fontSize: '16px', lineHeight: '1.6', paddingLeft: '40px', marginBottom: '20px' }}>
                    <li style={{ marginBottom: '8px' }}>Go To 'Live Battles'</li>
                    <li style={{ marginBottom: '8px' }}>Pick a game from the list (check the mini-charts for steady growth games).</li>
                    <li style={{ marginBottom: '8px' }}>
                      Click the green plus <span style={{ color: '#16e100' }}>[+]</span> next to the 'WIN' button.
                    </li>
                    <li style={{ marginBottom: '8px' }}>
                      In the Co-Hosting Popup, enter the amount you want to invest and click 'CONTRIBUTE'.
                    </li>
                    <li style={{ marginBottom: '8px' }}>
                      Sit back and wait for automatic payouts (if Host has enabled) or your share value to increase as its bankroll increases.
                    </li>
                  </ol>
                  <i style={{ fontSize: '16px', color: '#ff0000', display: 'block', padding: '0 20px', marginBottom: '30px' }}>
                    PRO TIP: You can track your earnings by searching for 'co-host' in All Transactions by clicking your wallet then View-All.
                  </i>

                  {/* New Definitions Section */}
                  <h3 style={{ fontSize: '24px', fontWeight: '600', marginTop: '30px', marginBottom: '15px', textAlign: 'center', letterSpacing: '0.5px' }}>
                    Definitions
                  </h3>
                  <h4 style={{ fontSize: '20px', fontWeight: '600', color: '#16e100', marginBottom: '10px', padding: '0 20px' }}>
                    Dividends (Auto-Payouts)
                  </h4>
                  <p style={{ fontSize: '16px', lineHeight: '1.6', marginBottom: '15px', textAlign: 'justify', padding: '0 20px' }}>
                    Dividends are payments made by a company to its shareholders, typically as a distribution of profits. These payments are usually made in cash or additional shares of stock.
                  </p>

                  <h4 style={{ fontSize: '20px', fontWeight: '600', color: '#16e100', marginBottom: '10px', padding: '0 20px' }}>
                    Shares (Stake)
                  </h4>
                  <p style={{ fontSize: '16px', lineHeight: '1.6', marginBottom: '15px', textAlign: 'justify', padding: '0 20px' }}>
                    Shares represent units of ownership in a company. When you own shares in a company, you own a portion of it, which may entitle you to vote on company decisions and earn dividends.
                  </p>

                  <Button style={{ display: 'block', margin: 'auto' }} onClick={this.togglePopup}>OK, GOT IT!</Button>
                </div>
                <button className="popup-close" onClick={this.togglePopup}>&times;</button>
              </div>
            </div>
          )}
        </Modal>
      </>
    )
  }
}

const mapStateToProps = state => ({
  creator: state.logic.curRoomInfo.creator_name,
  joiners: state.logic.curRoomInfo.joiners,
  isAuthenticated: state.auth.isAuthenticated,
  userName: state.auth.userName,
  recentlyClosed: state.logic.recentlyClosed,
  user: state.auth.user,
  isLowGraphics: state.auth.isLowGraphics,
  loading: state.logic.isActiveLoadingOverlay
});

const mapDispatchToProps = {
  getRoomList,
  setCurRoomInfo,
  actionRoom,
  getRoomStatisticsData,
  getRecentlyClosed,
  setBalance,
  fetchMoreRooms,
  getQbot,
  addNewTransaction,
  coHost
};

export default connect(mapStateToProps, mapDispatchToProps)(OpenGamesTable);
