
import React from 'react';

const LeaderboardsHover = () => {
  return (
<svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 96 96" width="96" height="96">
	<title>Leaderboards</title>
	<defs>
		<image  width="86" height="86" id="img2" href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFYAAABWCAMAAABiiJHFAAAAAXNSR0IB2cksfwAAAVlQTFRF2BcZ2BcZ2BcZ2BcZ2BcZ2BcZ2BcZ2BcZ2BcZ2BcZ2BcZ2BcZ2BcZ2BcZ2BcZ2BcZ2BcZ2BcZ2BcZ2BcZ2BcZ2BcZ2BcZ2BcZ2BcZ2BcZ2BcZ2BcZ2BcZ2BcZ2BcZ2BcZ2BcZ2BcZ2BcZ2BcZ2BcZ2BcZ2BcZ2BcZ2BcZ2BcZ2BcZ2BcZ2BcZ2BcZ2BcZ2BcZ2BcZ2BcZ2BcZ2BcZ2BcZ2BcZ2BcZ2BcZ2BcZ2BcZ2BcZ2BcZ2BcZ2BcZ2BcZ2BcZ2BcZ2BcZ2BcZ2BcZ2BcZ2BcZ2BcZ2BcZ2BcZ2BcZ2BcZ2BcZ2BcZ2BcZ2BcZ2BcZ2BcZ2BcZ2BcZ2BcZ2BcZ2BcZ2BcZ2BcZ2BcZ2BcZ2BcZ2BcZ2BcZ2BcZ2BcZ2BcZ2BcZ2BcZ2BcZ2BcZ2BcZ2BcZ2BcZ2BcZ2BcZ2BcZ2BcZ2BcZ2BcZ2BcZ2BcZ2BcZ2BcZ2BcZ2BcZEgZe2wAAAHN0Uk5TADOx6zJI+P/3Rwjj5Qlarq0K9fYLVVSop/LzA1+42d1eBW5bbNL5D56g9Nr+rA0O2H/NNiJ9xORBwwyi/XNcNDUkynd6imRlHSB2h8YBZgLHhrO6jCyA0xAoiCbmRAaUtBWSv29dtYkWhM/Og/GC0Do85/AlTP4AAAJtSURBVHic7dj7VxJBFAfwmxZDSIAKxSNNzUch+aQwRUjJxJDelm1KkqaYvf//H9rd2WEZGDjL3OGHPPv9Be498DnD7F6GA4DDXOnr7++76vTVTnPNQ/R4r6tVfQPEjP+GUjZArASUskHGhlSqg0OMHQ4rZCOknpsK2Vs2G1Wn+mI2G0ffC4nbI6N3jNC7a4hu8IDZGh0ZS8ip40HCJdhUh8al2AleIYG7TY1JKXaKR6ZnZqb5zpQUO8wj9wDu852kFDvbSKQezAHMh1KNvQUpdpG9fWk5nZ6jvfl0enmJ9R9KseFH7P2ZFbu7kmHdx5JzvLrGhOw6661nWW9tVU7Vk2PjlcrTxhOGxnLSqJ6NTYsp0PqpVW5uYFR91Lao84yW27TakhuwhuTpJhRhp/B8B4r0DstjVSiZzm75hT4dnsLLV2b5Gs0umE7pDf30b9+ZD7NYdY9yHnYHWE/2kOx7Iswikv0gZuW+vOrZF6uE7KPYjzaU1bSsXX1Csb76tTo4BDg8YJW3gmLhs+UcVY2qemSVX3AqhI/NxZ2UaVk+8Rr18SCShcTX6KlWtOvi2Wk0J3mWu/mvUzv/luQHN3nxvYZVIzEiSAz56zmSEqn6KYRya8K1GvlRRbDn7VRCfiLYX+3ZCwRL9+A33/xDrxqCpQtz1r0cbOs8xRWw4nnCsu3mCce2nycU22GeMGyHecKwHeYJw3YD9JSN8zslPjO6Z1sjODNUsIIzQwnbemaoYVvODEVs85nRPevozOieddR1WZd1WZd12d6ylbP6PyWq4tcqoKlGjWigfK1G/oK/F2ymN5tQgoqmfL36JfsHqpF0DVtVcz0AAAAASUVORK5CYII="/>
	</defs>
	<style>
	</style>
	<g id="Layer">
		<use id="icons8-rank" href="#img2" x="5" y="5"/>
	</g>
</svg>    );
};

export default LeaderboardsHover;


