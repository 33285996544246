import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Tabs, Tab, Drawer, RadioGroup, Radio, Grid, FormControlLabel } from '@material-ui/core';
import styled from 'styled-components';
import Lottie from 'react-lottie';
import Footer from './Footer';
import Summary from '../CreateGame/Summary';
import AdvancedSettings from '../CreateGame/AdvancedSettings';
import JukeboxPanel from '../../components/JukeboxPanel.jsx';

// import MyGamesTable from '../MyGames/MyGamesTable';
import LoadingOverlay from 'react-loading-overlay';
import MyHistoryTable from '../MyGames/MyHistoryTable';
import HistoryTable from '../LiveGames/HistoryTable';
import ChatPanel from '../ChatPanel/ChatPanel';
import DrawerButton from './DrawerButton';
import SupportButton from './SupportButton';
import AiPanel from '../../components/AiPanel';
import { getRank } from '../../util/getRank.js';
import animationData from '../LottieAnimations/cat-place-bet.json';
import RPS from '../CreateGame/RPS';
import Spleesh from '../CreateGame/Spleesh';
import MysteryBox from '../CreateGame/MysteryBox';
import DropGame from '../CreateGame/DropGame';
import BrainGame from '../CreateGame/BrainGame';
import PlayBrainGame from '../CreateGame/PlayBrainGame';
import QuickShoot from '../CreateGame/QuickShoot';
import Bang from '../CreateGame/Bang';
import Blackjack from '../CreateGame/Blackjack';
import Roll from '../CreateGame/Roll';

import history from '../../redux/history';
import { toggleDrawer } from '../../redux/Auth/user.actions';
import {
  createRoom,
  setGameMode,
  getMyGames,
  getMyHistory,
  getHistory,
  getMyChat,
  getGameTypeList,
  getStrategies
} from '../../redux/Logic/logic.actions';
import { getBrainGameType } from '../../redux/Question/question.action';
import { alertModal, confirmModalCreate } from '../modal/ConfirmAlerts';

import { convertToCurrency } from '../../util/conversion';

const StyledProductCard = styled.a`
  position: relative;
  background: ${({ isDarkMode }) =>
    isDarkMode
      ? 'linear-gradient(156deg, #ff0000, #971517)'
      : 'linear-gradient(156deg, #fd23e4, #cf0c0e)'};
  border-radius: 0.3em;
  width: 150px;
  justify-content: center;
  text-align: center;
  padding: 10px;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  -webkit-transition: -webkit-transform 0.2s;
  -webkit-transition: transform 0.2s;
  transition: transform 0.2s;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    // border-radius:10px;
    opacity: 0;
    transition: opacity 0.2s;
  }

  &:hover::before {
    opacity: 1;
  }

  &:hover {
    transform: translateY(-10px);
    border: 3px solid #fff;
    filter: brightness(3);
    transition: 0.3s;
    background: linear-gradient(86deg, #cf0c0e, #ff0333, #ff0909);

  }
    @media (max-width: 600px) {
    padding: 18px; /* Reduced padding for mobile */
  }
`;

const StyledRadioGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)); /* Adjust for larger screens */
  gap: 10px;
  max-width: 100%;
  margin: 20px auto;
  padding: 20px;
  
  /* Ensure one item per row on mobile */
  @media (max-width: 600px) {
    grid-template-columns: 1fr; /* Single column layout on mobile */
    padding: 10px; /* Adjust padding for mobile */
  }
`;

const StyledProductImage = styled.img`
  width: 50px;
  height: 100%;
  object-fit: contain; /* Ensures the image covers the entire container */
  border-radius: 10px;
`;

const customStyles = {
  tabRoot: {
    textTransform: 'none',
    width: '50%',
    // height: '48px',
    backgroundColor: 'rgba(0, 0, 0, 0.8)'
  }
};

const gifUrls = [
  'https://uploads-ssl.webflow.com/6097a2499efec713b2cb1c07/641ef8e1ce09cd9cf53a4829_rock1.gif',
  'https://uploads-ssl.webflow.com/6097a2499efec713b2cb1c07/641ef98d7e17a610c3ed83b9_paper2.gif',
  'https://uploads-ssl.webflow.com/6097a2499efec713b2cb1c07/641efdcadd850ab47a768e04_scissors1.gif'
];

const getRandomGifUrl = () => {
  const randomIndex = Math.floor(Math.random() * gifUrls.length);
  return gifUrls[randomIndex];
};

class CreateGame extends Component {
  constructor(props) {
    super(props);
    this.state = {
      is_mobile: window.innerWidth < 1024 ? true : false,
      selectedMobileTab: 'live_games',
      step: 1,
      child_step: 1,
      game_type: 1,
      game_mode: this.props.game_mode,
      isPlayingBrain: false,
      rps_list: [],
      drop_list: [],
      bang_list: [],
      bj_list: [],
      roll_list: [],
      qs_list: [],
      selectedStrategy: 'Hidden Markov',
      qs_game_type: 2,
      qs_nation: 0,
      selected_qs_position: 0,
      bet_amount: 0,
      endgame_amount: 0,
      spleesh_bet_unit: 0.01,
      max_return: 0,
      creatingRoom: false,
      max_prize: 0,
      winChance: 0,
      selectedGameType: this.props.game_mode,
      lowest_box_price: 0,
      public_bet_amount: convertToCurrency(0),
      is_private: false,
      room_password: '',
      aveMultiplier: 0,
      score: 0,
      youtubeUrl: '',
      lowerLimit: 0,
      upperLimit: 0,
      stopLossValue: 0,
      gameBackground: '',
      ai_mode: this.props.user.ai_mode,
      videoId: '',
      isPlaying: false,
      endgame_type: true,
      description: '',
      box_list: [],
      brain_game_type: this.props.brain_game_type,
      rps_game_type: 0,
      sounds: {
        wrong: new Audio('/sounds/wrong.mp3'),
        correct: new Audio('/sounds/correct.mp3'),
        boop: new Audio('/sounds/boop.mp3'),
        countDown: new Audio('/sounds/countDown.mp3'),
        grunt2: new Audio('/sounds/grunt2.mp3'),
        grunt: new Audio('/sounds/grunt.mp3'),
        select: new Audio('/sounds/select.mp3'),
        addTen: new Audio('/sounds/addTen.mp3'),
        tap: new Audio('/sounds/tap.mp3'),
        addBox: new Audio('/sounds/addBox.mp3'),
        cards: new Audio('/sounds/card.mp3')
      }
    };
    this.handleKeyPress = this.handleKeyPress.bind(this);

  }

  static getDerivedStateFromProps(props, current_state) {
    if (
      current_state.game_mode !== props.game_mode ||
      current_state.balance !== props.balance ||
      current_state.brain_game_type !== props.brain_game_type
    ) {
      return {
        ...current_state,
        game_mode: props.game_mode,
        brain_game_type: props.brain_game_type,
        balance: props.balance
      };
    }
    return null;
  }


  async componentDidMount() {
    document.addEventListener('keydown', this.handleKeyPress);

    Object.values(this.state.sounds).forEach(sound => {
      sound.load();
    });

    this.IsAuthenticatedReroute();
    this.props.getHistory();

    const gameTypeName = this.props.match.params.game_type_name;

    this.props.getGameTypeList();
    await this.props.setGameMode(gameTypeName);
    await this.initializeGameType(gameTypeName);

    if (this.props.isAuthenticated) {
      // this.props.getMyGames();
      this.props.getMyHistory();
      this.props.getMyChat();
    }
    // this.props.getBrainGameType();
    this.props.getStrategies();
  }

  async componentDidUpdate(prevProps) {
    const prevGameTypeName = prevProps.match.params.game_type_name;
    const currentGameTypeName = this.props.match.params.game_type_name;

    if (prevGameTypeName !== currentGameTypeName) {
      await this.initializeGameType(currentGameTypeName);
    }
  }

  async initializeGameType(gameTypeName) {
    const selectedGameType = this.props.gameTypeList.find(
      gameType => gameType.game_type_name === gameTypeName
    );

    if (selectedGameType && selectedGameType.short_name) {
      this.setState({ selectedGameType: selectedGameType.short_name });
    }

    let newState = {
      child_step: 1,
      bet_amount: 1,
      endgame_amount: 0,
      max_return: 0,
      max_prize: 0,
      lowest_box_price: 0,
      public_bet_amount: convertToCurrency(1)
    };

    if (gameTypeName === 'Spleesh!') {
      newState = {
        ...newState,
        game_type: 2,
        winChance: 0,
        endgame_amount: 0.54,
        max_return: 0.54,
        public_bet_amount: this.state
      };
    } else if (gameTypeName === 'RPS') {
      newState = {
        ...newState,
        game_type: 1,
        bet_amount: 0.0,
        winChance: 0,
        max_return: 0,
        endgame_amount: 0
      };
    } else if (gameTypeName === 'Quick Shoot') {
      newState = {
        ...newState,
        game_type: 5,
        public_bet_amount: convertToCurrency(1),
        max_return: 2,
        bet_amount: 1,
        winChance: 0,
        qs_nation: Math.floor(Math.random() * 4),
        endgame_amount: 0
      };
      // Add other game types here as needed
    }

    this.setState(newState);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyPress);
  }

  handleUrlChange = event => {
    this.setState({
      youtubeUrl: event.target.value
    });
  };
  handleDescriptionChange = event => {
    this.setState({
      description: event.target.value
    });
  };

  handleSubmit = event => {
    event.preventDefault();
    const { youtubeUrl } = this.state;
    const videoId = this.getVideoId(youtubeUrl);

    this.setState({
      videoId,
      isPlaying: true
    });
  };

  getVideoId = url => {
    // Check if the URL uses the "youtube.com" format
    if (url.includes('youtube.com')) {
      const videoId = url.split('v=')[1];
      const ampersandPosition = videoId.indexOf('&');
      if (ampersandPosition !== -1) {
        return videoId.substring(0, ampersandPosition);
      }
      return videoId;
    }
    // Check if the URL uses the "youtu.be" format
    if (url.includes('youtu.be')) {
      const videoId = url
        .split('/')
        .pop()
        .split('?')[0];
      return videoId;
    }
    return null; // Invalid URL format
  };

  onChangeState = async newState => {
    await this.setState(newState);
  };



  toggleDrawer = () => {
    this.props.toggleDrawer(!this.props.isDrawerOpen);
  };

  calculateEV(originalBet, maxSum, spleesh_bet_unit) {
    const sum = 55 * spleesh_bet_unit - originalBet; // sum of all possible wrong guesses
    const n = originalBet; // number of possible guesses
    const p = 1 / n; // probability of guessing correctly
    let expectedValue =
      originalBet * (sum - maxSum) * p - (sum - maxSum) * (1 - p);

    let scale = 1;
    if (maxSum < originalBet) {
      scale = (maxSum / originalBet) ** 2;
    } else if (maxSum > sum) {
      expectedValue *= -1;
      scale = -1;
    } else {
      scale = 1 - (sum - maxSum) / (sum - originalBet);
      scale = 1 - scale ** 2; // adjust scale based on quadratic function
    }

    expectedValue *= scale;
    return expectedValue / p;
  }

  setSelectedStrategy = (selectedStrategy) => {
    this.setState({ selectedStrategy });
  }

  calcMysteryBoxEV(boxList, targetSum, max_return) {
    const totalBoxes = boxList.length;
    const probability = 1 / totalBoxes;
    let expectedValue = 0;
    const totalPrizes = boxList.reduce((acc, curr) => acc + curr.box_prize, 0);
    const targetDiff = Math.abs(targetSum - totalPrizes);
    const maxDiff = Math.abs(max_return - totalPrizes);

    for (let i = 0; i < totalBoxes; i++) {
      const boxPrice = boxList[i].box_price;
      const boxPrize = boxList[i].box_prize;
      const boxEV =
        (boxPrice - boxPrize) * (1 - probability) - boxPrize * probability;
      expectedValue += boxEV;
    }

    let scale = 1;
    if (targetSum >= totalPrizes && targetSum <= max_return) {
      scale = 1 - targetDiff / maxDiff;
    } else if (targetSum > max_return) {
      expectedValue *= -1;
    }

    expectedValue *= scale;
    return expectedValue;
  }



  handleKeyPress(event) {
    const { selected_roll } = this.state;
    const { isFocused } = this.props;
    if (!isFocused) {
      switch (event.key) {
        case 'Escape':
          this.onPrevButtonClicked();
          break;
        case 'Enter':
          this.onNextButtonClicked();
          break;

        case 'Shift':
          this.onSkipButtonClicked();
          break;
        default:
          break;
      }
    }
  }

  IsAuthenticatedReroute = () => {
    if (!this.props.auth) {
      history.push('/');
    }
  };

  onSkipButtonClicked = () => {
    if ((this.state.step === 3) || this.state.step === 4 || this.state.step === 5) {

      if (this.state.child_step === 2 && this.state.game_mode === 'Mystery Box') {
        if (this.state.max_return > this.state.bet_amount * 4) {
          alertModal(this.props.isDarkMode, `TOO PURRROFITABLE! GAME IS UNFAIR`);
          return;
        }
      }
      this.setState({
        is_private: false,
        youtubeUrl: '',
        gameBackground: '',
        endgame_type: false,
        step: this.state.step + 1,
        child_step: this.state.child_step + 1
      });
    }
    return;
  };

  onStartBrainGame = e => {
    e.preventDefault();
    if (localStorage.getItem('hideConfirmModal') === 'true') {
      this.setState({
        step: 5,
        isPlayingBrain: true
      });
    } else {
      confirmModalCreate(
        this.props.isDarkMode,
        'ARE YOU SURE YOU CANNOT BET MORE, PUSSY?',
        'Okay',
        'Cancel',
        () => {
          this.setState({
            step: 5,
            isPlayingBrain: true
          });
        }
      );
    }
  };

  onPrevButtonClicked = () => {
    if (this.state.step < 5) {
      if (this.state.step === 3 && this.state.child_step === 1) {
        if (
          this.state.game_mode === 'Quick Shoot' ||
          (this.state.game_mode === 'RPS' && this.state.rps_game_type === 1)
        ) {
          this.setState({
            step: 2,
            child_step: 3,
            rps_list: []
          });
        } else {
          this.setState({
            step: 2,
            child_step: 2
          });
        }
        return;
      } else if (this.state.child_step > 1) {
        this.setState({
          child_step: this.state.child_step - 1
        });
        if ((this.state.step === 4) || (this.state.step === 2)) {
          this.setState({
            step: this.state.step - 1
          });
        }
        return;
      }
    }

    this.setState({
      step: this.state.step > 1 ? this.state.step - 1 : this.state.step
    });

    if (this.state.child_step === 4) {
      this.setState({
        child_step: this.state.child_step - 1
      });
    }

    if (this.state.step === 2) {
      this.setState({
        child_step: 1
      });
    }
  };

  onNextButtonClicked = () => {
    const {
      step,
      game_mode,
      child_step,
      bet_amount,
      balance,
      qs_list,
      rps_list,
      spleesh_bet_unit,
      endgame_amount,
      max_return,
      room_password,
      rps_game_type,
      endgame_type,
      is_private,
      selectedStrategy
    } = this.state;

    const { isDarkMode } = this.props;

    const alertAndReturn = message => {
      alertModal(isDarkMode, message);
      return;
    };

    const isMinimumRunsNeeded = (minRuns, list) => {
      if (list.length < minRuns) {
        alertAndReturn('PURR-HAPS IT WOULD BE WISE TO AT LEAST 3 RUNS FOR AI TRAINING DATA');
        return true;
      }
      return false;
    };
    if (step < 5) {

      if (step === 2) {
        if (
          (child_step === 1) &&
          (parseFloat(bet_amount) <= 0 || isNaN(parseFloat(bet_amount)))
        ) {
          alertAndReturn("YOU DIDN'T BET (CAT) SHIT!!!");
          return;
        }
        if (
          (child_step === 1) &&
          (parseFloat(bet_amount) < 10 || isNaN(parseFloat(bet_amount)))
        ) {
          alertAndReturn("MEOWNIMUM BET IS 10 RPS!");
          return;
        }


        if (bet_amount > balance) {
          alertAndReturn('NOT ENUFF FUNDS AT THIS MEOWMENT');
          return;
        }

        if (
          (game_mode === 'Quick Shoot') &&
          child_step === 1 &&
          (qs_list.length > 0)
        ) {
          this.setState({
            qs_list: [],
            winChance: 0,
            step: step > 1 ? step : step + 1
          });
          return;
        }

        let list;
        if (game_mode === 'Quick Shoot') {
          list = this.state.qs_list;
        } else if (game_mode === 'Bang!') {
          list = this.state.bang_list;
        } else if (game_mode === 'Blackjack') {
          list = this.state.bj_list;
        } else if (game_mode === 'Drop Game') {
          list = this.state.drop_list;
        } else {
          list = this.state[`${game_mode.toLowerCase()}_list`];
        }

        if (
          ((['Drop Game', 'Bang!', 'Roll', 'Blackjack'].includes(game_mode) &&
            child_step === 2) ||
            (
              ((game_mode === 'RPS' && rps_game_type === 0 && child_step === 2 && selectedStrategy === 'Hidden Markov') ||
                (game_mode === 'Quick Shoot' &&
                  child_step === 3 && selectedStrategy === 'Hidden Markov')) &&
              isMinimumRunsNeeded(3, list)
            ))) {
          return;
        }

        if (
          // (game_mode === 'RPS' && child_step < 3) ||
          (game_mode === 'Quick Shoot' && child_step < 3) ||
          (game_mode !== 'Mystery Box' && child_step === 1)
        ) {
          this.setState({
            child_step: child_step + 1
          });
          return;
        } else {
          // move to step 3
          this.setState({
            step: step + 1,
            child_step: 1
          });
          return;
        }
      }
      else if (child_step === 2 && step !== 1) {
        if (
          game_mode === 'Spleesh!' &&
          ((spleesh_bet_unit === 0.001 && endgame_amount < 0.044) ||
            (spleesh_bet_unit === 0.01 && endgame_amount < 0.44) ||
            (spleesh_bet_unit === 0.1 && endgame_amount < 4.4))
        ) {
          alertAndReturn(
            `TOO PURRROFITABLE! MINIMUM PAYOUT IS ${44 * spleesh_bet_unit}`
          );
          return;
        } else if (
          game_mode === 'Mystery Box' &&
          (max_return > bet_amount * 4 || endgame_amount < bet_amount)
        ) {
          if (max_return > bet_amount * 4) {
            alertAndReturn('TOO PURRROFITABLE! GAME IS UNFAIR');
          } else {
            alertAndReturn('THIS GAME REQUIRES A PAYOUT MORE THAN BET AMOUNT');
          }
          return;
        } else if (isNaN(endgame_amount)) {
          alertAndReturn('IM-PAW-SIBBLEEE, ENTER A VALID NUMBER!');
          return;
        } else if (endgame_type && endgame_amount < parseFloat(bet_amount)) {
          alertAndReturn('CANNOT BE LOWER THAN BANKROLL');
          return;
        }
        this.setState({
          child_step: child_step + 1
        });
      } else if (child_step === 1 && step !== 1) {
        if (is_private === true && room_password === '') {
          alertAndReturn('SET THE PASSWORD TO JOIN YOUR GAME!');
          return;
        }
        if (endgame_amount === 0) {
          this.setState({ endgame_type: false });
        }
        this.setState({
          child_step: child_step + 1
        });
      }
      this.setState({
        step: step > 3 && child_step < 3 ? step : step + 1,
        child_step: step === 1 ? child_step : child_step + 1
      });
    }
    return;
  };

  playSound = sound => {
    if (!this.props.isMuted) {
      const audio = this.state.sounds[sound];
      audio.currentTime = 0;
      audio.play();
    }
  };

  onCreateRoom = async () => {
    if (localStorage.getItem('hideConfirmModal') === 'true') {
      this.setState({ creatingRoom: true });
      await this.props.createRoom(this.state);
      this.setState({ creatingRoom: false });
    } else {
      confirmModalCreate(
        this.props.isDarkMode,
        'CONFIRM GAME SETTINGS?',
        'LFG',
        'Cancel',
        async () => {
          this.setState({ creatingRoom: true });
          await this.props.createRoom(this.state);
          this.setState({ creatingRoom: false });

        }
      );
    }
  };

  handleChange = (event) => {
    const { gameTypeList } = this.props;
    const selectedShortName = event.target.value;

    // Find the game type object based on the short_name
    const selectedGame = gameTypeList.find(game => game.short_name === selectedShortName);

    if (selectedGame) {
      const selectedGameName = selectedGame.game_type_name;

      // Update the URL with the game_type_name
      const url = `/create/${selectedGameName}`;

      // Update the state and call setGameMode
      this.setState({ selectedGameType: event.target.value });
      this.props.setGameMode(selectedGameName);
      history.push(url);
    }
  };


  step1 = () => {
    const gameTypeStyleClass = {
      RPS: 'rps',
      'S!': 'spleesh',
      MB: 'mystery-box',
      BG: 'brain-game',
      QS: 'quick-shoot',
      DG: 'drop-game',
      'B!': 'bang',
      R: 'roll',
      BJ: 'blackjack'
    };

    const { gameTypeList } = this.props;
    const { selectedGameType } = this.state;

    if (!gameTypeList) return null;

    // if (this.props.gameTypeList !== 'All') {
    return (
      <div>
        <RadioGroup value={selectedGameType} onChange={this.handleChange}>
          <StyledRadioGrid>
            {gameTypeList.map((gameType, index) => (
              <FormControlLabel
                key={index}
                value={gameType.short_name}
                control={<Radio
                  icon={<div style={{ display: 'none' }} />}
                  checkedIcon={<div style={{ display: 'none' }} />}
                />}
                label={
                  <StyledProductCard
                    id="rps-game-type-radio"
                    isDarkMode={this.props.isDarkMode}

                    className={`btn-game-type title btn-icon ${gameTypeStyleClass[gameType.short_name]} ${selectedGameType === gameType.short_name ? 'active' : ''
                      }`}
                  >
                    <StyledProductImage
                      src={`/img/gametype/icons/${gameType.short_name}.svg`}
                      alt={gameType.game_type_name}
                    />
                    <span style={{color: "#f9f9f9"}}>{gameType.game_type_name}</span>
                  </StyledProductCard>
                }
              />
            ))}
          </StyledRadioGrid>
        </RadioGroup>
      </div>
    );
    // }
  };

  step2 = () => {
    const { game_mode, child_step, selectedStrategy } = this.state;

    switch (game_mode) {
      case 'RPS':
        return (
          <RPS
            setSelectedStrategy={this.setSelectedStrategy}
            selectedStrategy={selectedStrategy}
            ai_mode={this.props.user.ai_mode}
            user_id={this.props.user._id}
            rank={getRank(this.props.user.totalWagered)}
            playSound={this.playSound}
            strategies={this.props.strategies}
            onChangeState={this.onChangeState}
            rps_list={this.state.rps_list}
            bet_amount={this.state.bet_amount}
            winChance={this.state.winChance}
            is_private={this.state.is_private}
            room_password={this.state.room_password}
            step={child_step}
            endgame_amount={this.state.endgame_amount}
            rps_game_type={this.state.rps_game_type}
            youtubeUrl={this.state.youtubeUrl}
            gameBackground={this.state.gameBackground}
          />
        );
      case 'Spleesh!':
        return (
          <Spleesh
            playSound={this.playSound}
            calculateEV={this.calculateEV}
            onChangeState={this.onChangeState}
            bet_amount={this.state.bet_amount}
            spleesh_bet_unit={this.state.spleesh_bet_unit}
            is_private={this.state.is_private}
            room_password={this.state.room_password}
            winChance={this.state.winChance}
            endgame_type={this.state.endgame_type}
            endgame_amount={this.state.endgame_amount}
            step={child_step}
            youtubeUrl={this.state.youtubeUrl}
            gameBackground={this.state.gameBackground}
          />
        );

      case 'Mystery Box':
        return (
          <MysteryBox
            playSound={this.playSound}
            calcMysteryBoxEV={this.calcMysteryBoxEV}
            onChangeState={this.onChangeState}
            box_list={this.state.box_list}
            bet_amount={this.state.bet_amount}
            max_return={this.state.max_return}
            max_prize={this.state.max_prize}
            winChance={this.state.winChance}
            endgame_amount={this.state.endgame_amount}
            calcWinChance={this.calcWinChance}
            youtubeUrl={this.state.youtubeUrl}
            gameBackground={this.state.gameBackground}
          />
        );
      case 'Brain Game':
        return (
          <BrainGame
            playSound={this.playSound}
            youtubeUrl={this.state.youtubeUrl}
            gameBackground={this.state.gameBackground}
            onChangeState={this.onChangeState}
            bet_amount={this.state.bet_amount}
            winChance={this.state.winChance}
            brain_game_type={this.state.brain_game_type}
            step={this.state.child_step}
            endgame_amount={this.state.endgame_amount}
          />
        );
      case 'Quick Shoot':
        return (
          <QuickShoot
            setSelectedStrategy={this.setSelectedStrategy}
            selectedStrategy={selectedStrategy}
            ai_mode={this.props.user.ai_mode}
            playSound={this.playSound}
            onChangeState={this.onChangeState}
            qs_list={this.state.qs_list}
            user_id={this.props.user._id}
            bet_amount={this.state.bet_amount}
            is_private={this.state.is_private}
            endgame_type={this.state.endgame_type}
            strategies={this.props.strategies}
            winChance={this.state.winChance}
            room_password={this.state.room_password}
            endgame_amount={this.state.endgame_amount}
            qs_game_type={this.state.qs_game_type}
            selected_qs_position={this.state.selected_qs_position}
            step={this.state.child_step}
            qs_nation={this.state.qs_nation}
            youtubeUrl={this.state.youtubeUrl}
            gameBackground={this.state.gameBackground}
          />
        );
      case 'Drop Game':
        return (
          <DropGame
            playSound={this.playSound}
            onChangeState={this.onChangeState}
            drop_list={this.state.drop_list}
            bet_amount={this.state.bet_amount}
            winChance={this.state.winChance}
            is_private={this.state.is_private}
            room_password={this.state.room_password}
            step={this.state.child_step}
            endgame_amount={this.state.endgame_amount}
            youtubeUrl={this.state.youtubeUrl}
            gameBackground={this.state.gameBackground}
          />
        );
      case 'Bang!':
        return (
          <Bang
            playSound={this.playSound}
            onChangeState={this.onChangeState}
            bang_list={this.state.bang_list}
            bet_amount={this.state.bet_amount}
            winChance={this.state.winChance}
            aveMultiplier={this.state.aveMultiplier}
            is_private={this.state.is_private}
            room_password={this.state.room_password}
            step={this.state.child_step}
            endgame_amount={this.state.endgame_amount}
            youtubeUrl={this.state.youtubeUrl}
            gameBackground={this.state.gameBackground}
          />
        );
      case 'Roll':
        return (
          <Roll
            playSound={this.playSound}
            onChangeState={this.onChangeState}
            roll_list={this.state.roll_list}
            bet_amount={this.state.bet_amount}
            winChance={this.state.winChance}
            aveMultiplier={this.state.aveMultiplier}
            is_private={this.state.is_private}
            is_anonymous={this.state.is_anonymous}
            room_password={this.state.room_password}
            step={this.state.child_step}
            endgame_amount={this.state.endgame_amount}
            youtubeUrl={this.state.youtubeUrl}
            gameBackground={this.state.gameBackground}
          />
        );
      case 'Blackjack':
        return (
          <Blackjack
            playSound={this.playSound}
            onChangeState={this.onChangeState}
            bj_list={this.state.bj_list}
            bet_amount={this.state.bet_amount}
            winChance={this.state.winChance}
            is_private={this.state.is_private}
            room_password={this.state.room_password}
            step={this.state.child_step}
            endgame_amount={this.state.endgame_amount}
            child_step={this.state.child_step}
            youtubeUrl={this.state.youtubeUrl}
            gameBackground={this.state.gameBackground}
          />
        );

      default:
        this.props.setGameMode(game_mode)
        return this.step1();
    }
  };


  action_panel = () => {
    const { isLowGraphics } = this.props;
    return (
      <>
        <hr />
        <div className="action-panel">
          {
            this.state.step === 1 ? (
              <span></span>
            ) : (
              <Button id="btn_prev" onClick={this.onPrevButtonClicked}>
                Previous&nbsp;<span className="roll-tag">[Esc]</span>
              </Button>
            )}
          {(this.state.step === 3 || this.state.step === 4) && (
            <Button id="btn_skip" onClick={this.onSkipButtonClicked}>
              Skip&nbsp;<span className="roll-tag">[Shift]</span>
            </Button>
          )}

          {this.state.step === 5 &&
            this.state.game_mode === 'Brain Game' &&
            !this.state.isPlayingBrain && (
              <>
                <div style={{ position: 'relative', marginLeft: '-37.5%' }}>
                  <Button id="btn_bet" onClick={this.onStartBrainGame}>
                    Start
                  </Button>
                  <div className="lottie-container-btn_bet">
                    <Lottie
                      options={{
                        loop: this.props.isLowGraphics ? false : true,
                        autoplay: this.props.isLowGraphics ? false : true,
                        animationData: animationData
                      }}
                      style={{
                        width: '62px',
                        position: 'absolute',
                        filter: 'grayscale(1)'
                      }}
                    />
                  </div>
                </div>
              </>

            )}

          {this.state.step === 5 && this.state.game_mode !== 'Brain Game' && (
            <>
              <div style={{ position: 'relative', marginLeft: '-37.5%' }}>
                <Button id="btn_bet" onClick={this.onCreateRoom}>
                  Place Bet
                </Button>
                <div className="lottie-container-btn_bet">
                  <Lottie
                    options={{
                      loop: isLowGraphics ? false : true,
                      autoplay: isLowGraphics ? false : true,
                      animationData: animationData
                    }}
                    style={{
                      width: '62px',
                      position: 'absolute',
                      filter: 'grayscale(1)'
                    }}
                  />
                </div>
              </div>
            </>
          )}
          {this.state.step < 5 && (
            <Button id="btn_next" onClick={this.onNextButtonClicked}>
              Next&nbsp;<span className="roll-tag">[Enter]</span>
            </Button>
          )}
        </div>
      </>
    );
  };

  getActiveTabText = () =>
    (this.state.is_mobile && this.state.selectedMobileTab === 'live_games') ||
      (!this.state.is_mobile && this.props.selectedMainTabIndex === 0) ? (
      <div id="liveStakes">{this.props.roomCount} LIVE BATTLES</div>
    ) : (
      'Your Battles'
    );

  render() {
    const { isDrawerOpen } = this.props;
    const { selectedStrategy } = this.state;
    return (
      <LoadingOverlay
        className="custom-loading-overlay"
        active={this.state.creatingRoom}
        spinner={
          <div className="rpsLoader">
            <img
              src={getRandomGifUrl()}
              alt="Random Spinner"
              style={{
                width: '40px',
                display: 'block',
                marginLeft: 'auto',
                marginRight: 'auto',
                marginBottom: '10px'
              }}
            />
            <div>Creating Battle Room...</div>
          </div>
        }
      >
        <div
          className="main-game"
          style={{
            gridTemplateColumns: this.props.isDrawerOpen
              ? '260px calc(100% - 610px) 350px'
              : 'calc(100% - 350px) 350px'
          }}
        >
          {((this.state.is_mobile && this.state.selectedMobileTab === 'chat') ||
            !this.state.is_mobile) && (
              <Drawer
                className="mat-chat"
                style={{ display: this.props.isDrawerOpen ? 'flex' : 'none' }}
                variant="persistent"
                anchor="left"
                open={this.props.isDrawerOpen}
              >
                <ChatPanel />
              </Drawer>
            )}
          {!this.state.is_mobile &&
            (!this.state.selectedMobileTab === 'live_games' ||
              !this.state.selectedMobileTab === 'my_games') && (
              <Tabs
                value={this.state.show_open_game}
                onChange={this.showOpenGameOrHistory}
                TabIndicatorProps={{ style: { background: '#ff0000' } }}
                className="main-game-page-tabs"
              >
                <Tab
                  label={
                    this.state.selectedMobileTab === 'live_games'
                      ? 'Live Battles'
                      : 'Your Battles'
                  }
                  style={customStyles.tabRoot}
                />
                <Tab label="History" style={customStyles.tabRoot} />
              </Tabs>
            )}
          <div className="main-panel">
            <h2 className="main-title desktop-only">
              {this.getActiveTabText()}
            </h2>
            <div className="create-game-panel">
              <div className="game-page">
                <div className="page-title">
                  <h2 className="title">CREATE {this.state.game_mode} BATTLE</h2>
                  {this.state.step === 6 &&
                    this.state.game_mode === 'Brain Game' &&
                    this.state.isPlayingBrain ? (
                    <Summary
                      bet_amount={this.state.bet_amount}
                      max_return={this.state.max_return}
                      endgame_type={this.state.endgame_type}
                      endgame_amount={this.state.endgame_amount}
                      is_private={this.state.is_private}
                      step={this.state.step}
                      aveMultiplier={this.state.aveMultiplier}
                      child_step={this.state.child_step}
                      game_mode={this.state.game_mode}
                      max_prize={this.state.max_prize}
                      winChance={this.state.winChance}
                      description={this.state.description}
                      public_bet_amount={this.state.public_bet_amount}
                      youtubeUrl={this.state.youtubeUrl}
                      gameBackground={this.state.gameBackground}
                      selectedStrategy={selectedStrategy}

                    />
                  ) : (
                    <span>Click the manual icon at the top for help.</span>
                  )}
                </div>
                <div className="game-contents title">
                  {(this.state.step !== 5 ||
                    this.state.game_mode !== 'Brain Game' ||
                    !this.state.isPlayingBrain) && (
                      <Summary
                        bet_amount={this.state.bet_amount}
                        max_return={this.state.max_return}
                        endgame_type={this.state.endgame_type}
                        selectedStrategy={selectedStrategy}
                        endgame_amount={this.state.endgame_amount}
                        is_private={this.state.is_private}
                        step={this.state.step}
                        description={this.state.description}
                        aveMultiplier={this.state.aveMultiplier}
                        calcAveMultiplier={this.props.calcAveMultiplier}
                        child_step={this.state.child_step}
                        rps_game_type={this.state.rps_game_type}
                        qs_game_type={this.state.qs_game_type}
                        game_mode={this.state.game_mode}
                        max_prize={this.state.max_prize}
                        public_bet_amount={this.state.public_bet_amount}
                        spleesh_bet_unit={this.state.spleesh_bet_unit}
                        winChance={this.state.winChance}
                        calcWinChance={this.props.calcWinChance}
                        youtubeUrl={this.state.youtubeUrl}
                        gameBackground={this.state.gameBackground}
                      />
                    )}
                  {this.state.step === 1 && this.step1()}
                  {this.state.step === 2 && this.step2()}
                  {(this.state.step === 3 || this.state.step === 4) && (
                    <AdvancedSettings
                      calculateEV={this.calculateEV}
                      max_return={this.state.max_return}
                      calcMysteryBoxEV={this.calcMysteryBoxEV}
                      spleesh_bet_unit={this.state.spleesh_bet_unit}
                      onChangeState={this.onChangeState}
                      lowerLimit={this.state.lowerLimit}
                      upperLimit={this.state.upperLimit}
                      stopLossValue={this.state.stopLossValue}
                      winChance={this.state.winChance}
                      bet_amount={this.state.bet_amount}
                      is_private={this.state.is_private}
                      room_password={this.state.room_password}
                      game_mode={this.state.game_mode}
                      endgame_type={this.state.endgame_type}
                      description={this.state.description}
                      endgame_amount={this.state.endgame_amount}
                      is_anonymous={this.state.is_anonymous}
                      step={this.state.step}
                      child_step={this.state.child_step}
                      box_list={this.state.box_list}
                      youtubeUrl={this.state.youtubeUrl}
                      gameBackground={this.state.gameBackground}
                      videoId={this.state.videoId}
                      isPlaying={this.state.isPlaying}
                      handleSubmit={this.handleSubmit}
                      handleUrlChange={this.handleUrlChange}
                      handleDescriptionChange={this.handleDescriptionChange}
                    />
                  )}
                  {this.state.step === 5 &&
                    this.state.game_mode === 'Brain Game' &&
                    this.state.isPlayingBrain && (
                      <PlayBrainGame
                        playSound={this.playSound}
                        brain_game_type={this.state.brain_game_type}
                        bet_amount={this.state.bet_amount}
                        is_private={this.state.is_private}
                        is_anonymous={this.state.is_anonymous}
                        room_password={this.state.room_password}
                        endgame_amount={this.state.endgame_amount}
                        youtubeUrl={this.state.youtubeUrl}
                        videoId={this.state.videoId}
                      />
                    )}
                  {this.state.step !== 6 && this.action_panel()}
                </div>
              </div>
            </div>
          </div>
          <div className="sub-panel">

            {!this.state.is_mobile && this.props.selectedMainTabIndex === 0 && (
              <>
                <h2 className="main-title desktop-only">HISTORY</h2>
                <HistoryTable />
              </>
            )}

            {!this.state.is_mobile && this.props.selectedMainTabIndex === 1 && (
              <>
                {/* <h2 className="main-title desktop-only">JUKEBOX</h2>
                <JukeboxPanel isMusicEnabled={this.props.isMusicEnabled} /> */}

                <h2 className="main-title desktop-only">AI PANEL</h2>
                <AiPanel user_id={this.props.user_id} />
                <h2 className="main-title desktop-only" style={{marginBottom: "10px"}}>YOUR HISTORY</h2>

                <MyHistoryTable />
              </>
            )}

            <DrawerButton
              open={this.props.isDrawerOpen}
              toggleDrawer={this.toggleDrawer}
            />
            <SupportButton
              open={this.props.isSupportOpen}
            // toggleDrawer={this.toggleDrawer}
            />
          </div>
          {!this.state.is_mobile && (
            <div className="mobile-only main-page-nav-button-group">
              <Button
                className={`mobile-tab-live ${this.state.selectedMobileTab === 'live_games' ? 'active' : ''
                  }`}
                onClick={e => {
                  this.setState({ selectedMobileTab: 'live_games' });
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  fill="none"
                  viewBox="0 0 32 32"
                >
                  <path
                    stroke={
                      this.state.selectedMobileTab === 'live_games'
                        ? '#fff'
                        : '#8E9297'
                    }
                    strokeWidth="1.5"
                    d="M24.9 25.945c2.625-2.578 4.1-6.076 4.1-9.722 0-3.647-1.475-7.144-4.1-9.723M7.1 25.945C4.476 23.367 3 19.87 3 16.223 3 12.576 4.475 9.079 7.1 6.5M21 22.5c1.92-1.658 3-3.906 3-6.25s-1.08-4.592-3-6.25M14 17.678v-3.356c0-.79.871-1.268 1.537-.844l2.637 1.678c.618.393.618 1.295 0 1.688l-2.637 1.678c-.666.424-1.537-.055-1.537-.844zM11 22.5c-1.92-1.658-3-3.906-3-6.25s1.08-4.592 3-6.25"
                  />
                </svg>
                {this.state.selectedMobileTab === 'live_games' &&
                  'LIVE BATTLES'}
              </Button>
              <Button
                className={`mobile-tab-my ${this.state.selectedMobileTab === 'my_games' ? 'active' : ''
                  }`}
                onClick={e => {
                  this.setState({ selectedMobileTab: 'my_games' });
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="33"
                  height="32"
                  fill="none"
                  viewBox="0 0 33 32"
                >
                  <path
                    stroke={
                      this.state.selectedMobileTab === 'my_games'
                        ? '#fff'
                        : '#8E9297'
                    }
                    strokeWidth="1.5"
                    d="M27.5 27.5c0-2.917-1.159-5.715-3.222-7.778-2.063-2.063-4.86-3.222-7.778-3.222-2.917 0-5.715 1.159-7.778 3.222C6.659 21.785 5.5 24.582 5.5 27.5"
                  />
                  <path
                    fill={
                      this.state.selectedMobileTab === 'my_games'
                        ? '#fff'
                        : '#8E9297'
                    }
                    d="M18.651 12.702l-.674.33.674-.33zm-.294-.602l.674-.33c-.126-.257-.387-.42-.674-.42v.75zm-3.714 0v-.75c-.287 0-.548.163-.674.42l.674.33zm7.607-4.75v4.302h1.5V7.35h-1.5zm-2.925 5.022l-.294-.601-1.348.658.294.602 1.348-.659zm-.968-1.022h-3.714v1.5h3.714v-1.5zm-4.388.42l-.294.602 1.348.66.294-.603-1.348-.658zm-3.219-.118V7.35h-1.5v4.302h1.5zm2.036-6.402h7.428v-1.5h-7.428v1.5zm-.49 8c-.838 0-1.546-.7-1.546-1.598h-1.5c0 1.695 1.348 3.098 3.046 3.098v-1.5zm8.408 0c-.576 0-1.113-.333-1.379-.878l-1.348.66c.512 1.046 1.565 1.718 2.727 1.718v-1.5zm1.546-1.598c0 .899-.708 1.598-1.546 1.598v1.5c1.698 0 3.046-1.403 3.046-3.098h-1.5zm-8.575.72c-.266.545-.803.878-1.38.878v1.5c1.163 0 2.216-.672 2.728-1.719l-1.348-.659zM23.75 7.35c0-1.972-1.567-3.6-3.536-3.6v1.5c1.109 0 2.036.924 2.036 2.1h1.5zm-13 0c0-1.176.928-2.1 2.036-2.1v-1.5c-1.969 0-3.536 1.628-3.536 3.6h1.5zm1.571 1.7h2.786v-1.5h-2.786v1.5zm.643-2.175v2.85h1.5v-2.85h-1.5zM19.75 8.1h.929V6.6h-.929v1.5zM17.893 10h.928V8.5h-.928V10z"
                  />
                </svg>
                {this.state.selectedMobileTab === 'my_games' && 'YOUR BATTLES'}
              </Button>
              <button
                className={`mobile-tab-marketplace ${this.state.selectedMobileTab === 'marketplace' ? 'active' : ''
                  }`}
                onClick={e => {
                  this.setState({ selectedMobileTab: 'marketplace' });
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  fill="none"
                  viewBox="0 0 32 32"
                >
                  <path
                    stroke={
                      this.state.selectedMobileTab === 'marketplace'
                        ? '#fff'
                        : '#8E9297'
                    }
                    strokeWidth="1.5"
                    d="M10.083 6.083h11.833v8.584c0 3.268-2.649 5.917-5.916 5.917-3.268 0-5.917-2.65-5.917-5.917V6.083zM9.333 26.666h13.333M22.223 14.597c3.528-.571 4.444-4.538 4.444-6.597H22M9.777 14.597C6.25 14.026 5.333 10.06 5.333 8H10M16 21.334v5.333"
                  />
                </svg>
                {this.state.selectedMobileTab === 'marketplace' &&
                  'MARKETPLACE'}
              </button>
              <Button
                className={`mobile-tab-chat ${this.state.selectedMobileTab === 'chat' ? 'active' : ''
                  }`}
                onClick={e => {
                  this.setState({ selectedMobileTab: 'chat' });
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="33"
                  height="32"
                  fill="none"
                  viewBox="0 0 33 32"
                >
                  <path
                    stroke={
                      this.state.selectedMobileTab === 'chat'
                        ? '#fff'
                        : '#8E9297'
                    }
                    strokeWidth="1.5"
                    d="M28 16c0 6.351-5.149 11.5-11.5 11.5-1.407 0-2.754-.253-4-.715-2.75-1.02-4.091 1.378-4.75.965-.685-.43 1.328-2.929.75-3.489C6.342 22.171 5 19.242 5 16 5 9.649 10.149 4.5 16.5 4.5S28 9.649 28 16z"
                  />
                  <circle
                    cx="10.5"
                    cy="16"
                    r="2"
                    stroke={
                      this.state.selectedMobileTab === 'chat'
                        ? '#fff'
                        : '#8E9297'
                    }
                    strokeWidth="1.5"
                  />
                  <circle
                    cx="16.5"
                    cy="16"
                    r="2"
                    stroke={
                      this.state.selectedMobileTab === 'chat'
                        ? '#fff'
                        : '#8E9297'
                    }
                    strokeWidth="1.5"
                  />
                  <circle
                    cx="22.5"
                    cy="16"
                    r="2"
                    stroke={
                      this.state.selectedMobileTab === 'chat'
                        ? '#fff'
                        : '#8E9297'
                    }
                    strokeWidth="1.5"
                  />
                </svg>
                {this.state.selectedMobileTab === 'chat' && 'CHAT'}
              </Button>
            </div>
          )}
          <Footer
            className="footer"
            open={this.props.isDrawerOpen}
            style={{ marginLeft: this.props.isDrawerOpen ? '270px' : '0' }}
          />
        </div>
      </LoadingOverlay>
    );
  }
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  history: state.logic.history,
  pageNumber: state.logic.pageNumber,
  balance: state.auth.balance,
  user: state.auth.user,
  gameTypeList: state.logic.gameTypeList,
  selectedMainTabIndex: state.logic.selectedMainTabIndex,

  auth: state.auth.isAuthenticated,
  landingItemList: state.landingReducer.landingItemList,
  game_mode: state.logic.game_mode,
  strategies: state.logic.strategies,
  socket: state.auth.socket,
  balance: state.auth.balance,
  brain_game_type: state.questionReducer.brain_game_type,
  isDarkMode: state.auth.isDarkMode,
  isLowGraphics: state.auth.isLowGraphics,
  isDrawerOpen: state.auth.isDrawerOpen,
  isFocused: state.auth.isFocused
});

const mapDispatchToProps = {
  createRoom,
  setGameMode,
  getBrainGameType,
  getHistory,
  getMyGames,
  getStrategies,
  getMyHistory,
  getGameTypeList,
  getMyChat,
  toggleDrawer
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateGame);
