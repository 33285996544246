
import React from 'react';

const Battle = () => {
  return (
	<svg  version="1.0" xmlns="http://www.w3.org/2000/svg"  width="15pt" height="15pt" viewBox="0 0 100.000000 100.000000"  preserveAspectRatio="xMidYMid meet">  <g transform="translate(0.000000,100.000000) scale(0.050000,-0.050000)" fill="#999999" stroke="none"> <path d="M250 1820 c-7 -12 15 -106 50 -211 l63 -189 409 -365 c472 -421 431 -395 472 -309 19 40 58 78 94 93 85 35 104 7 -308 465 l-369 412 -186 62 c-208 70 -208 70 -225 42z"/> <path d="M1520 1779 c-160 -55 -178 -67 -315 -213 -173 -187 -170 -148 -20 -311 l125 -136 165 156 c161 150 168 161 226 335 86 258 84 259 -181 169z"/> <path d="M642 938 c-25 -33 -42 -64 -39 -67 4 -3 35 -20 69 -38 34 -17 74 -59 88 -92 30 -75 29 -75 112 -2 l66 58 -110 102 c-131 119 -128 118 -186 39z"/> <path d="M269 879 c-37 -40 -34 -44 227 -305 l264 -263 41 41 42 42 -89 178 -90 178 -169 85 c-198 99 -179 96 -226 44z"/> <path d="M1500 831 l-170 -87 -86 -175 -87 -175 42 -42 41 -41 264 263 c261 261 264 265 227 305 -47 51 -30 55 -231 -48z"/> <path d="M287 499 c-30 -45 -83 -94 -120 -109 -74 -31 -103 -102 -68 -168 46 -87 188 -69 212 26 7 26 52 76 101 111 105 76 105 76 22 157 -83 80 -83 80 -147 -17z"/> <path d="M1564 514 c-81 -83 -81 -79 24 -155 49 -35 94 -85 101 -111 24 -95 166 -113 212 -26 35 66 6 137 -68 168 -37 15 -90 64 -120 109 -65 98 -68 98 -149 15z"/> </g> </svg> 
  );
};

export default Battle;


